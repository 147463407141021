import { Button } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";
import { Buffer } from "buffer";

const AudioPlayer = ({ audioBuffers, setCompleted, completed }) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const audioRef = useRef(null);
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = 0.87;
    }
  }, [audioUrl]);

  const getAudioUrl = (buffer) => {
    const uint8Array = new Uint8Array(buffer.data);
    const blob = new Blob([uint8Array], { type: "audio/mpeg" });
    return URL.createObjectURL(blob);
  };

  useEffect(() => {
    if (audioBuffers[currentTrackIndex]) {
      function base64ToAudioBuffer(base64String) {
        const base64Data = base64String.replace(/^data:audio\/\w+;base64,/, "");

        return {
          type: "Buffer",
          data: Buffer.from(base64Data, "base64"),
        };
      }

      const url = getAudioUrl(
        base64ToAudioBuffer(audioBuffers[currentTrackIndex])
      );

      setAudioUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [currentTrackIndex, audioBuffers]);

  useEffect(() => {
    if (isPlaying && audioRef.current) {
      audioRef.current.play();
    }
  }, [audioUrl, isPlaying]);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleEnded = () => {
    if (currentTrackIndex < audioBuffers.length - 1) {
      setCurrentTrackIndex((prevIndex) => prevIndex + 1);
    } else {
      setCompleted(!completed);
      setCurrentTrackIndex(0);
      setIsPlaying(false);
    }
  };

  return (
    <div>
      {audioUrl && (
        <audio
          ref={audioRef}
          src={audioUrl}
          onEnded={handleEnded}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          autoPlay
        />
      )}

      <Button onClick={handlePlayPause} disabled={!audioUrl}>
        {isPlaying ? dict.pause[lang] : dict.play[lang]}
      </Button>
    </div>
  );
};

export default function AudioConverter({ audio, setCompleted, completed }) {
  const [audioBuffers, setAudioBuffers] = useState([]);

  useEffect(() => {
    if (audio) {
      const buffers = [];
      for (const iterator of audio) {
        buffers.push(iterator.audio);
      }

      setAudioBuffers(() => buffers);
    }
  }, [audio]);

  return (
    <div>
      <AudioPlayer
        audioBuffers={audioBuffers}
        setCompleted={setCompleted}
        completed={completed}
      />
    </div>
  );
}
