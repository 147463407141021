import { Select } from "antd";
import { useState, useEffect } from "react";

const handoverOptions = [
  { label: "Handover", value: "Handover" },
  {
    label: "Police Handover",
    value: "Police Handover",
  },
  {
    label: "NGO Handover",
    value: "NGO Handover",
  },
  // { label: "Child Handover", value: "Child Handover" },
  // { label: "Male Handover", value: "Male Handover" },
  // { label: "Female Handover", value: "Female Handover" },
  // {
  //   label: "Telephonic Trace or Self",
  //   value: "Telephonic Trace or Self - टेलीफोनिक ट्रेस या स्वयं",
  // },
];

const CommonSelectOption = ({
  setType,
  type,
  selectOption,
  placeholder,
  name,
  form,
}) => {
  const handleChange = (value) => {
    setType(value);
    form && form.setFieldsValue({ [name]: value });
  };

  const [options, setOptions] = useState(handoverOptions);

  useEffect(() => {
    form && form.setFieldsValue({ [name]: type });
    if (selectOption) {
      setOptions(() => selectOption);
    }
  }, [form, selectOption, type, name]);

  return (
    <div className="flex w-full justify-end">
      <Select
        size={placeholder ? "small" : "default"}
        value={type}
        style={{ width: 200 }}
        placeholder={placeholder || "Select Handover Type"}
        onChange={handleChange}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default CommonSelectOption;
const { Option } = Select;
