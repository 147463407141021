import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import { Outlet, useLocation, useNavigate } from "react-router";
import MainNavbar from "../navbar/MainNavbar";
import CopyWrite from "../footer/Copywrite";
import { DICT } from "../utils/dictionary";
import landingBg from "../assets/Dashboard/login_bg.jpg";

const Layout = () => {
  const localLang = localStorage.getItem("lang");
  const [lang, setLang] = useState(localLang || "en");

  useEffect(() => {
    if (lang) {
      localStorage.setItem("lang", lang);
    }
  }, [lang]);

  const props = { dict: DICT, lang: lang };
  const [formPage, setFormPage] = useState();

  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes("dashboard") ||
      location.pathname.includes("welcome") ||
      location.pathname.includes("lostperson-form") ||
      location.pathname.includes("foundperson-form") ||
      location.pathname.includes("tv-configuration")
    ) {
      setFormPage("w-full px-3 flex flex-col");
    } else if (!location.pathname.split("-").includes("form")) {
      setFormPage(
        "md:w-10/12 w-11/12 xs:w-9/12 xl:w-10/12 2xl:w-9/12 flex flex-col m-auto"
      );
    } else {
      setFormPage("w-11/12 2xl:w-11/12 flex flex-col m-auto");
    }
  }, [location]);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("sessionToken");
    if (!token && !location.pathname.includes("welcome")) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-[#f6f6f6] font-roboto">
      <div>
        <MainNavbar lang={lang} setLang={setLang}></MainNavbar>
        <div className={`${formPage}`}>
          <Outlet context={[props.dict, props.lang]}></Outlet>
        </div>
      </div>
      <div
        className={
          location.pathname !== "/tv-configuration"
            ? "w-full mt-auto"
            : "hidden"
        }
      >
        <div className="h-10"></div>

        <div
          style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 3)" }}
          className="px-3"
        >
          <div
            className="relative flex overflow-hidden bg-cover bg-bottom "
            style={{ backgroundImage: `url(${landingBg})` }}
          >
            <div className="bg-slate-500 w-full bg-opacity-60 ">
              <div className="md:w-10/12 w-11/12 xl:w-10/12 2xl:w-9/12  flex flex-col m-auto z-30 p-2">
                <Footer {...props}></Footer>
              </div>
              <div>
                <CopyWrite {...props}></CopyWrite>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
