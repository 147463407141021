import React from "react";
import SourceRadio from "./HandoverSource";
import { DatePicker, Form, Input, Select } from "antd";
import {
  disableFutureDates,
  limitLength,
  validateMobileNumber,
} from "../../utils/commonFunctions";
import { DICT, langingPage } from "../../utils/dictionary";
import { useOutletContext } from "react-router";
import WebcamCapture from "../../commonComponents/WebcamCapture";
import { numberValidator } from "../../utils/nameValudator";

const NGOHandover = ({ form, setImage, image, setImageSrcMain }) => {
  const dict = DICT;
  const dict1 = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  return (
    <div className="flex gap-3">
      <div>
        <WebcamCapture
          setImage={setImage}
          image={image}
          setImageSrcMain={setImageSrcMain}
        ></WebcamCapture>
      </div>
      <div className=" mt-3 grid w-full grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2 h-fit">
        <Form.Item
          label={dict1.select_date[lang]}
          name={"receiver_handover_date"}
          rules={[{ required: true, message: dict1.please_select_date[lang] }]}
        >
          <DatePicker
            size="small"
            style={{ width: "100%" }}
            disabledDate={disableFutureDates}
            placeholder={dict1.select_date[lang]}
          />
        </Form.Item>

        <Form.Item
          label={dict1.name[lang]}
          name={"receiver_name"}
          rules={[
            {
              required: true,
              message: dict1.please_enter_receiver_name[lang],
            },
          ]}
        >
          <Input size="small" placeholder={dict1.enter_name[lang]} />
        </Form.Item>

        <Form.Item
          label={dict1.gender[lang]}
          name={"receiver_gender"}
          rules={[
            { required: true, message: dict1.please_select_gender[lang] },
          ]}
        >
          <Select size="small" placeholder={dict1.select_gender[lang]}>
            <Option value="M">{dict1.male[lang]}</Option>
            <Option value="F">{dict1.female[lang]}</Option>
            <Option value="O">{dict1.other[lang]}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="receiver_age"
          label={<div className="font-semibold">{dict1.age_year[lang]}</div>}
          rules={[
            { required: true, message: dict1.please_input_age[lang] },
            {
              validator: numberValidator(
                dict1.please_enter_a_valid_age[lang],
                100
              ),
            },
          ]}
        >
          <Input
            max={100}
            onChange={(e) => limitLength(e, "receiver_age", form, 3)}
            size="small"
            type="number"
            className="rounded-none"
            placeholder={dict1.enter_age[lang]}
          />
        </Form.Item>
        <Form.Item
          label={dict1.phone_number[lang]}
          name={"receiver_mobile_no"}
          rules={[
            {
              required: "true",
              message: dict1.please_enter_phone_number[lang],
            },
            { validator: validateMobileNumber(dict) },
          ]}
        >
          <Input
            onChange={(e) => {
              limitLength(e, "receiver_mobile_no", form, 10);
            }}
            size="small"
            placeholder={dict1.enter_phone_number[lang]}
          />
        </Form.Item>

        <Form.Item
          label={dict1.ngo_name[lang]}
          name={"receiver_ngo_name"}
          rules={[
            {
              required: true,
              message: dict1.ngo[lang],
            },
          ]}
        >
          <Input size="small" placeholder={dict1.ngo_name[lang]} />
        </Form.Item>

        <Form.Item
          label={dict1.address[lang]}
          name={"receiver_address"}
          rules={[
            { required: true, message: dict1.please_enter_address[lang] },
          ]}
        >
          <Input size="small" placeholder={dict1.address[lang]} />
        </Form.Item>

        <Form.Item label={dict1.remark[lang]} name={"receiver_remark"}>
          <Input size="small" placeholder={dict1.remark[lang]} />
        </Form.Item>

        <div className="col-span-5">
          <SourceRadio></SourceRadio>
        </div>
      </div>
    </div>
  );
};

export default NGOHandover;
const { Option } = Select;
