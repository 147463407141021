import React, { useEffect, useState } from "react";
import { Button, Input, Select, Form, DatePicker } from "antd";

import CommonNotification from "../../commonComponents/CommonNotification";
import CountryStateCity from "../../commonComponents/CountryStateCity";
import { postData, getData, patchData } from "../../Fetch/Axios";
import dayjs from "dayjs";
import getLanguages from "../../utils/getLanguages";
import {
  nameLikeFieldsValidator,
  numberValidator,
} from "../../utils/nameValudator";
import URLS from "../../Fetch/URLS";
import CommonUploader from "../../commonComponents/CommonUploader";
import CommonFormDropDownMaker from "../../commonComponents/CommonFormDropDownMaker";
import CommonDivider from "../../commonComponents/CommonDivider";
import CaptureImage from "./captureImage";
import {
  base64ToFile,
  calculateAge,
  disableFutureDates,
  limitLength,
  smallFieldStyle,
  validateAadharNumber,
  validateMobileNumber,
} from "../../utils/commonFunctions";
import FoundPersonsByName from "./FoundPersonsByName";
import ImageCropper from "./ImageCroper";
import { langingPage } from "../../utils/dictionary";
import { useNavigate, useOutletContext, useParams } from "react-router";
import FaceDetectionOptions from "./FaceDetectionOptions";
import { Link } from "react-router-dom";
import AdditionalInfo from "./AdditionalInfo";
import PrintModal from "../../commonComponents/PrintModal";
import utc from "dayjs/plugin/utc";
import AudioNotepad from "../../commonComponents/AudioNotepad";
dayjs.extend(utc);

const { Option } = Select;

const LostForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [supportingDoc1, setSupportingDoc1] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [image, setImage] = useState(null);
  const [imageToUpload, setImageToUpload] = useState();
  const [searchDetails, setSearchDetails] = useState([]);
  const [pdfUrl, setPdfUrl] = useState();
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  const navigate = useNavigate();

  useEffect(() => {
    if (imageSrc) {
      let image;

      if (imageSrc && typeof imageSrc !== "object") {
        const fileObj = base64ToFile(imageSrc);
        setImageToUpload(() => image);

        image = fileObj;
      } else if (imageSrc) {
        image = imageSrc;
        setImageToUpload(() => image);
      }
    } else {
      setImageToUpload(false);
    }
  }, [imageSrc]);

  const [search, setSearch] = useState({});

  const params = useParams();
  const [personInfo, setPersonInfo] = useState();

  useEffect(() => {
    const getInfo = async (id) => {
      const details = await getData(
        URLS.searchLostPerson + "/ids/" + [id] + "/?fields=-updatedAt"
      );

      const el = details?.docs[0];
      setPersonInfo(() => el);
    };

    if (params.id) {
      getInfo(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (personInfo) {
      personInfo.dob && (personInfo.dob = dayjs(personInfo.dob));

      if (personInfo?.case_resolved_at) {
        personInfo.case_resolved_at = dayjs(personInfo?.case_resolved_at);
      }

      if (personInfo?.attire_color_id) {
        personInfo.attire_color_id = personInfo?.attire_color_id?._id;
        personInfo.attire_id = personInfo?.attire_id?._id;
      }

      personInfo?.country_id?._id &&
        (personInfo.country_id = personInfo?.country_id._id);

      personInfo?.state_id?._id &&
        (personInfo.state_id = personInfo?.state_id._id);

      personInfo?.city_id?._id &&
        (personInfo.city_id = personInfo?.city_id._id);

      personInfo.last_seen_date = dayjs(personInfo.last_seen_date);
      personInfo.created_at = dayjs(personInfo.createdAt);

      form.setFieldsValue(personInfo);
    } else {
      form.setFieldsValue({ created_at: dayjs(new Date(Date.now())) });
    }
  }, [form, personInfo]);

  const [notificationInfo, setNotificationInfo] = useState({
    label: "",
    desc: "",
  });

  const onFinish = async (values) => {
    setLoading(true);
    setPdfUrl();

    delete values["created_at"];

    if (values.case_resolved_at) {
      values.case_resolved_at = dayjs(values.case_resolved_at);
    }

    values = {
      ...values,
      dob: values.dob ? values.dob + "" : null,
      lastSeenDate: values.lastSeenDate ? values.lastSeenDate + "" : null,
      found_remark: "NA",
      photo: imageToUpload,
    };

    if (supportingDoc1) {
      values.supporting_doc_1 = base64ToFile(supportingDoc1);
    }

    const formData = new FormData();

    for (const key in values) {
      if (values[key]) formData.append(key, values[key]);
    }

    let response;

    if (!personInfo) {
      response = await postData(formData, URLS.searchLostPerson);
    } else {
      formData.append("complaint_number", personInfo.complaint_number);

      response = await patchData(
        formData,
        URLS.searchLostPerson + "/" + personInfo._id
      );
    }

    if (response?.status === "success") {
      const id = response.doc._id;
      const formData2 = new FormData();

      formData2.append("face", imageToUpload);
      formData2.append("compalaint_no", id);
      formData2.append("purpose", "lost");

      try {
        if (imageToUpload) {
          const formData = new FormData();
          formData.append("name", "lost");
          formData.append("photos", imageToUpload);
          formData.append("collections", id);

          if (imageSrc) {
            postData(
              {
                externalId: id,
                encoded_image: imageSrc.split("base64,")[1].toString(),
              },
              URLS.mxAddIdentity,
              false
            );
          }
        }

        setLoading(false);
        form.resetFields();
      } catch (error) {
        setLoading(false);
      }

      setPdfUrl(URLS.receipt_base_url + response.doc.receipt);

      form.resetFields();
      setImageToUpload(false);
      setSearchDetails(() => []);
      setImage(false);
      setImageSrc(false);

      if (personInfo) {
        navigate("/register-lostperson-form");
      }

      setPersonInfo();
    } else {
      setLoading(false);
    }
  };

  const [selectedVillageId, setSelectedVillageId] = useState();
  const [disability, setDisability] = useState("No");

  const [open, setOpen] = useState(false);

  return (
    <div>
      {smallFieldStyle()}
      <div>
        {pdfUrl && (
          <PrintModal
            url={pdfUrl}
            message="Lost Person Registration Status"
            setPdfUrl={setPdfUrl}
          ></PrintModal>
        )}

        <ImageCropper
          base64String={imageSrc}
          setImageSrc={setImageSrc}
          open={open}
          setIsModalOpen={setOpen}
        ></ImageCropper>
        <AudioNotepad></AudioNotepad>

        <CommonDivider label={dict.enroll_lost_person_1[lang]}></CommonDivider>

        <CommonNotification
          desc={notificationInfo.desc}
          label={notificationInfo.label}
        ></CommonNotification>

        <div className="md:grid grid-cols-10 flex gap-1 flex-col ">
          <div className="flex flex-col gap-1 w-full pt-0 col-span-2 justify-start ">
            {personInfo?.photo && (
              <div>
                <img
                  className="flex w-full"
                  src={URLS.lost_photo_base_url + personInfo.photo}
                  alt={personInfo.photo}
                ></img>
              </div>
            )}

            {imageSrc && (
              <div className="flex flex-col gap-1 ">
                <div className="flex flex-col gap-2 justify-center items-center overflow-hidden p-1">
                  <img
                    className="border w-full "
                    src={
                      typeof imageSrc === "object"
                        ? URL.createObjectURL(imageSrc)
                        : typeof imageSrc === "string"
                        ? imageSrc
                        : URLS.user_pho_base_url + "dummy.png"
                    }
                    alt=""
                  />

                  <div className=" text-center  relative  z-50 -mt-9  w-full px-2 py-1  bg-blue-500 bg-opacity-30 text-white rounded text-sm">
                    <button
                      onClick={() => {
                        setImage();
                        setImageSrc();
                      }}
                    >
                      Retake
                    </button>
                  </div>
                </div>
              </div>
            )}

            {!imageSrc && (
              <div className="overflow-hidden flex flex-col w-full  border items-start ">
                <CaptureImage
                  purpose={"lost"}
                  setImageSrcMain={setImageSrc}
                  image={image}
                  setImage={setImage}
                ></CaptureImage>
              </div>
            )}

            {imageSrc && (
              <div className="flex w-full justify-center">
                <Button
                  size="small"
                  className="bg-teal-100 text-sm font-semibold"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Crop Image
                </Button>
              </div>
            )}

            <div className="border w-full p-1">
              <FaceDetectionOptions
                image={imageToUpload}
                purpose="lost"
                baseUrl={URLS.lost_photo_base_url}
              ></FaceDetectionOptions>

              {/* <FaceDetectionResult
                image={imageToUpload}
                purpose="lost"
                title="Lost Persons by Image"
                baseUrl={URLS.lost_photo_base_url}
              ></FaceDetectionResult> */}
            </div>
          </div>

          <div className="flex flex-col gap-1 border col-span-6">
            <div className="bg-white p-2 rounded-md">
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{ receipt_lang: "hi" }}
              >
                <div className="border p-2 -mb-4">
                  <h2 className="text-base font-bold mb-3">
                    {dict.lost_person_information[lang]}
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2">
                    <Form.Item
                      name="first_name"
                      label={
                        <div className="font-semibold">
                          {dict.first_name[lang]}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: dict.please_enter_first_name[lang],
                        },
                        {
                          validator: nameLikeFieldsValidator(
                            dict.please_enter_a_valid_first_name[lang]
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        className="rounded-none"
                        placeholder={dict.enter_first_name[lang]}
                        onInput={(e) => {
                          setSearch((prev) => {
                            return { ...prev, first_name: e.target.value };
                          });
                        }}
                      />
                    </Form.Item>

                    {/* <Form.Item
                    name="first_name_hindi"
                    label={
                      <div className="font-semibold">First Name (Hindi) </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: dict.please_enter_first_name[lang],
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      className="rounded-none"
                      placeholder={dict.enter_first_name[lang]}
                    />
                  </Form.Item> */}
                    <Form.Item
                      name="last_name"
                      label={
                        <div className="font-semibold">
                          {dict.last_name[lang]}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: dict.please_enter_last_name[lang],
                        },
                        {
                          validator: nameLikeFieldsValidator(
                            dict.please_enter_a_valid_last_name[lang]
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        className="rounded-none"
                        placeholder={dict.enter_last_name[lang]}
                        onInput={(e) => {
                          setSearch((prev) => {
                            return { ...prev, last_name: e.target.value };
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      name="name_hindi"
                      label={<div className="font-semibold">Hindi Name</div>}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        className="rounded-none"
                        placeholder={dict.enter_name[lang]}
                      />
                    </Form.Item>
                    {/* <Form.Item
                    name="last_name_hindi"
                    label={
                      <div className="font-semibold">Last Name (Hindi) </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: dict.please_enter_last_name[lang],
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      className="rounded-none"
                      placeholder={dict.enter_last_name[lang]}
                    />
                  </Form.Item> */}
                    <Form.Item
                      name="gender"
                      label={
                        <div className="font-semibold">{dict.gender[lang]}</div>
                      }
                      rules={[
                        {
                          required: true,
                          message: dict.please_select_a_gender[lang],
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        placeholder={dict.select_gender[lang]}
                      >
                        <Option value="M">{dict.male[lang]}</Option>
                        <Option value="F">{dict.female[lang]}</Option>
                        <Option value="O">Other</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="dob"
                      label={
                        <div className="font-semibold">
                          {dict.date_of_birth[lang]}
                        </div>
                      }
                    >
                      <DatePicker
                        size="small"
                        onChange={(date) => {
                          const age = calculateAge(date);
                          form.setFieldsValue({ age: age });
                          setSearch((prev) => {
                            return {
                              ...prev,
                              dob: dayjs(date).format("YYYY-MM-DD"),
                            };
                          });
                        }}
                        placeholder={dict.select_date[lang]}
                        className="rounded-none w-full"
                        disabledDate={disableFutureDates}
                      />
                    </Form.Item>
                    <Form.Item
                      name="age"
                      label={
                        <div className="font-semibold">
                          {dict.age_year[lang]}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: dict.please_input_age[lang],
                        },
                        {
                          validator: numberValidator(
                            dict.please_enter_a_valid_age[lang],
                            100
                          ),
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => limitLength(e, "age", form, 3)}
                        size="small"
                        type="number"
                        className="rounded-none"
                        placeholder={dict.enter_age[lang]}
                      />
                    </Form.Item>
                    <Form.Item
                      name="height"
                      label={
                        <div className="font-semibold">{dict.height[lang]}</div>
                      }
                    >
                      <Select
                        placeholder={dict.select_height[lang]}
                        size="small"
                      >
                        <Option value="Short">{dict.short[lang]}</Option>
                        <Option value="Tall">{dict.tall[lang]}</Option>
                        <Option value="Medium">{dict.medium[lang]}</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="complexion"
                      label={
                        <div className="font-semibold">
                          {dict.select_complexion[lang]}
                        </div>
                      }
                    >
                      <Select
                        placeholder={dict.select_relative_1[lang]}
                        size="small"
                      >
                        <Option value="Fair">{dict.fair[lang]}</Option>
                        <Option value="Wheatish/Genhua">
                          {dict.wheatish_genhua[lang]}
                        </Option>
                        <Option value="Dark/Saanwla">
                          {dict.dark_saanwla[lang]}
                        </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="disability_desc"
                      label={
                        <div className="font-semibold">
                          {dict.health_condition[lang]}
                        </div>
                      }
                      rules={[
                        {
                          required: disability === "Yes",
                          message: dict.please_input_health_condition[lang],
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        className="rounded-none"
                        placeholder={dict.enter_health_condition[lang]}
                      />
                    </Form.Item>
                    <Form.Item
                      name="any_mark"
                      label={
                        <div className="font-semibold">
                          {dict.any_marking[lang]}
                        </div>
                      }
                    >
                      <Input
                        size="small"
                        className="rounded-none"
                        placeholder={dict.enter_any_marking[lang]}
                      />
                    </Form.Item>
                    <CommonFormDropDownMaker
                      name="attire_id"
                      placeholder={dict.select_attire[lang]}
                      required={"true"}
                      RequiredMessage={dict.attire_is_required[lang]}
                      responseIdName={"_id"}
                      selectLabel={dict.select_attire[lang]}
                      responseLabelName={"name"}
                      selectName={"attire_id"}
                      uri={"attire"}
                      size="small"
                      setValue={(val) => {
                        setSearch((prev) => {
                          return { ...prev, attire_id: val };
                        });
                      }}
                    ></CommonFormDropDownMaker>
                    <CommonFormDropDownMaker
                      name="attireColor_id"
                      placeholder={dict.select_attire_color[lang]}
                      required={"true"}
                      RequiredMessage={dict.attire_color_is_required[lang]}
                      responseIdName={"_id"}
                      selectLabel={dict.select_attire_color[lang]}
                      responseLabelName={"name"}
                      selectName={"attire_color_id"}
                      uri={"attire_color"}
                      size="small"
                      setValue={(val) => {
                        setSearch((prev) => {
                          return { ...prev, attire_color_id: val };
                        });
                      }}
                    ></CommonFormDropDownMaker>
                    <Form.Item
                      name="lost_place"
                      label={
                        <div className="font-semibold">
                          {dict.lost_place[lang]}
                        </div>
                      }
                    >
                      <Input
                        size="small"
                        className="rounded-none"
                        placeholder={dict.lost_place[lang]}
                      />
                    </Form.Item>
                    <Form.Item
                      name="last_seen_date"
                      label={
                        <div className="font-semibold">
                          {dict.last_seen_date[lang]}
                        </div>
                      }
                    >
                      <DatePicker
                        defaultValue={dayjs(Date.now())}
                        size="small"
                        className="rounded-none w-full"
                        disabledDate={disableFutureDates}
                        placeholder={dict.select_date[lang]}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <div className="font-semibold">
                          {dict.language_spoken[lang]}
                        </div>
                      }
                      name="language_spoken"
                      className="mb-4"
                    >
                      <Select
                        size="small"
                        placeholder={dict.select_language[lang]}
                        options={getLanguages()}
                        showSearch
                      ></Select>
                    </Form.Item>
                    <CountryStateCity
                      form={form}
                      setSelectedVillageId={(id) => setSelectedVillageId(id)}
                      setCityId={(val) => {
                        setSearch((prev) => {
                          return { ...prev, city_id: val };
                        });
                      }}
                    />
                    <Form.Item
                      name={"taluka"}
                      label={
                        <div className="font-semibold">{dict.taluka[lang]}</div>
                      }
                    >
                      <Input
                        size="small"
                        className="rounded-none"
                        placeholder={dict.enter_taluka[lang]}
                      />
                    </Form.Item>

                    <div className="col-span-2">
                      <Form.Item
                        name="police_station"
                        label={
                          <div className="font-semibold">
                            {dict.police_station_post_office[lang]}
                          </div>
                        }
                      >
                        <Input
                          size="small"
                          className="rounded-none"
                          placeholder={dict.police_station_post_office[lang]}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name={"village"}
                      label={
                        <div className="font-semibold">
                          {dict.village[lang]}
                        </div>
                      }
                    >
                      <Input
                        size="small"
                        className="rounded-none"
                        placeholder={dict.enter_village[lang]}
                      />
                    </Form.Item>

                    <Form.Item
                      name="address"
                      label={
                        <div className="font-semibold">
                          {dict.address[lang]}
                        </div>
                      }
                    >
                      <Input
                        onChange={(e) => limitLength(e, "address", form, 35)}
                        size="small"
                        className="rounded-none"
                        placeholder={dict.enter_address[lang]}
                      />
                    </Form.Item>

                    <Form.Item
                      name="aadhar_no"
                      label={
                        <div className="font-semibold">
                          {dict.aadhar_number[lang]}
                        </div>
                      }
                      rules={[{ validator: validateAadharNumber }]}
                    >
                      <Input
                        onChange={(e) => limitLength(e, "aadhar_no", form, 12)}
                        size="small"
                        className="rounded-none"
                        placeholder={dict.aadhar_number[lang]}
                      />
                    </Form.Item>

                    <div className="flex flex-col">
                      <CommonUploader
                        name="supporting_doc_1"
                        label={
                          <div className="font-semibold">
                            {dict.supporting_doc_1[lang]}
                          </div>
                        }
                        setPhoto={setSupportingDoc1}
                        size={"small"}
                        onlyImage={false}
                      ></CommonUploader>

                      {personInfo?.supporting_doc_1 &&
                        !form.getFieldsValue("supporting_doc_1")
                          ?.supporting_doc_1 && (
                          <div className="-mt-7 mb-3">
                            <Link
                              target="_blank"
                              to={
                                URLS.lost_photo_base_url +
                                personInfo.supporting_doc_1
                              }
                            >
                              Supporting Document
                            </Link>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="border p-2 pt-3 mt-4 -pb-8">
                  <h2 className="text-base font-bold -mt-4 mb-3">
                    {dict.complainant_information[lang]}
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-x-2">
                    <Form.Item
                      name="complainant_relation"
                      label={
                        <div className="font-semibold">
                          {dict.relation[lang]}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: dict.please_select_relation[lang],
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        placeholder={dict.select_relation[lang]}
                      >
                        <Option value="Family">{dict.family[lang]}</Option>
                        <Option value="Friend">{dict.friend[lang]}</Option>
                        <Option value="Other">{dict.other[lang]}</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="complainant_name"
                      label={
                        <div className="font-semibold">
                          {dict.complainant_name[lang]}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: dict.please_enter_name[lang],
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        className="rounded-none"
                        placeholder={dict.complainant_name[lang]}
                      />
                    </Form.Item>

                    <Form.Item
                      name={"complainant_mob_number"}
                      label={
                        <div className="font-semibold">
                          {dict.mobile_no[lang]}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: dict.please_input_mobile_number[lang],
                        },
                        { validator: validateMobileNumber(dict, lang) },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          limitLength(e, "complainant_mob_number", form, 10);

                          if (e.target.value.length === 10) {
                            setSearch((prev) => {
                              return {
                                ...prev,
                                complainant_mob_number: e.target.value,
                              };
                            });
                          }
                        }}
                        size="small"
                        className="rounded-none"
                        placeholder={dict.complainant_mob_number[lang]}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="mt-3 mb-3">
                  <AdditionalInfo
                    lang={lang}
                    caseResolved={true}
                    form={form}
                  ></AdditionalInfo>
                </div>

                <div className="flex justify-end">
                  <Button
                    type="default"
                    htmlType="reset"
                    className="rounded-none bg-yellow-500  font-bold py-2 px-4 mr-2"
                    onClick={() => form.resetFields()}
                  >
                    {dict.reset[lang]}
                  </Button>

                  <Button
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    className="bg-green-500  font-bold py-2 px-4 rounded-none"
                  >
                    {dict.submit[lang]}
                  </Button>
                </div>
              </Form>
            </div>
          </div>

          <div className="border w-full h-full p-1 col-span-2">
            <FoundPersonsByName vals={search}></FoundPersonsByName>
            <FoundPersonsByName
              vals={search}
              time={1000}
              form={form}
            ></FoundPersonsByName>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LostForm;
