import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { postData } from "../../Fetch/Axios";
import URLS from "../../Fetch/URLS";

import { limitLength } from "../../utils/commonFunctions";
import ChangePassword from "./ChangeYourPassword";
import NumericCaptcha from "../NumericCaptcha";
import { langingPage } from "../../utils/dictionary";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const [isInvalidCaptcha, setisInvalidCaptcha] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [userInput, setUserInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dict = langingPage;

  const handleValidate = () => {
    if (userInput !== captcha) {
      setErrorMessage("Invalid Captcha!");
      setisInvalidCaptcha(true);
      return false;
    } else {
      return true;
    }
  };

  const onFinish = async (values) => {
    const isCorrect = handleValidate();
    if (isCorrect) {
      setUserInput("");
    }

    if (isCorrect) {
      setLoading(true);
      const res = await postData(values, URLS.forgotPassword, true);
      if (res) {
        setLoading(false);
        setDone(true);
        localStorage.setItem("mob", values.phone);
      } else {
        setLoading(false);
      }
      if (res) {
        setisInvalidCaptcha(true);
        setErrorMessage("");
      } else {
        setUserInput("");
        setErrorMessage("");
        setisInvalidCaptcha(true);
      }
    }
  };

  const [form] = Form.useForm();

  return (
    <div className="h-screen w-screen flex justify-center ">
      {!done ? (
        <div className="mt-48">
          <div className="flex flex-col text-start font-semibold sm:text-2xl w-full justify-center md:items-center text-green-800 ">
            Maha Kumbh Mela 2025
          </div>

          <Form
            onFinish={onFinish}
            className="max-w-96 border border-gray-200 h-fit p-2 mt-2"
            layout="vertical"
          >
            <Form.Item
              name="phone"
              rules={[
                { required: true, message: "Pease enter your phone number!" },
                {
                  type: "phone",
                },
              ]}
              label={
                <div className="text-sm">
                  <div className="text-xl text-center mb-3 font-semibold">
                    Reset your password
                  </div>
                  <div>
                    Enter your user account's verified mobile number and we will
                    send you a OTP to reset password!
                  </div>
                </div>
              }
            >
              <Input
                placeholder="Enter Your Mobile Number"
                onChange={(e) => limitLength(e, "phone", form, 10)}
              ></Input>
            </Form.Item>

            <NumericCaptcha
              setisInvalidCaptcha={setisInvalidCaptcha}
              isInvalidCaptcha={isInvalidCaptcha}
              captcha={captcha}
              setCaptcha={setCaptcha}
              userInput={userInput}
              setUserInput={setUserInput}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            ></NumericCaptcha>

            <Form.Item noStyle>
              <div className="flex justify-end w-full">
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="bg-green-500 text-white font-semibold w-full mt-3"
                >
                  Send
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div className="max-w-96 relative top-20 border border-gray-200 h-fit p-3 text-center">
          {/* <div className="w-full mt-3 flex items-center justify-center">
            <Link to="/login" className="w-full">
              <Button className="w-full">Return to sign in</Button>
            </Link>
          </div> */}
          <ChangePassword></ChangePassword>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
