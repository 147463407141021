import React from "react";
import HandoverType from "./HandoverForms/CommonSelectOption";
import { useState } from "react";
import CommonHandover from "./HandoverForms/CommonHandover";
import CommonDivider from "../commonComponents/CommonDivider";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";
import AudioNotepad from "../commonComponents/AudioNotepad";

const HandoverForm = () => {
  const [type, setType] = useState("Handover");
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  return (
    <div>
      <CommonDivider
        label={dict.handover[lang]}
        other={
          <div className="mb-2 flex gap-2 items-center font-semibold">
            Type:
            <HandoverType type={type} setType={setType}></HandoverType>
          </div>
        }
      ></CommonDivider>
      <AudioNotepad></AudioNotepad>
      <CommonHandover type={type}></CommonHandover>
    </div>
  );
};

export default HandoverForm;
