import { Form, DatePicker } from "antd";
import React from "react";
import "antd/dist/reset.css";
import { disableFutureDates } from "../utils/commonFunctions";
const { RangePicker } = DatePicker;

const AntdFormItem = ({ name, required = true }) => {
  return (
    <Form.Item
      noStyle
      name={name}
      rules={[{ required: required, message: "Please select a date range!" }]}
    >
      <RangePicker disabledDate={disableFutureDates} />
    </Form.Item>
  );
};

export default AntdFormItem;
