import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./zoom.css";

const CommonDivider = ({ label, backCompo, compo, other, classP }) => {
  return (
    <div className="mt-3 flex gap-2">
      {backCompo && (
        <Link to={backCompo.path}>
          <Button className="rounded-full w-9 h-9 bg-gray-100">
            <ArrowLeftOutlined className=""></ArrowLeftOutlined>
          </Button>
        </Link>
      )}
      <div className="w-full">
        {/* {compo && (
        <Link to={compo.path} className="p-1">
          <Button className="bg-slate-300 p-2 ">
            <PlusOutlined size="large"></PlusOutlined>
          </Button>
        </Link>
      )} */}
        <div className={`flex w-full justify-between `}>
          <div
            className={`text-green-700 font-semibold text-2xl w-full ${classP} ${
              classP ? "text-center" : ""
            }`}
          >
            {label}
          </div>
          {other}

          {!other && compo && (
            <Link to={compo.path} className="p-1">
              <Button className="bg-blue-100 p-2 ">
                <PlusOutlined size="large"></PlusOutlined>
              </Button>
            </Link>
          )}
        </div>
        <Divider className="bg-green-300 mt-0 mb-3" />
      </div>
    </div>
  );
};

export default CommonDivider;
