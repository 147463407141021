import React, { useState } from "react";
import Announcement from "./Announcement";
import LocalAnnoucements from "./LocalAnnoucements";

const AnnouceOptions = () => {
  const [local, setLocal] = useState(false);

  return !local ? (
    <Announcement setLocal={setLocal}></Announcement>
  ) : (
    <LocalAnnoucements setLocal={setLocal}></LocalAnnoucements>
  );
};

export default AnnouceOptions;
