import dayjs from "dayjs";
import { getData } from "../Fetch/Axios";
import Resizer from "react-image-file-resizer";
import printJS from "print-js";
import { message } from "antd";
import URLS from "../Fetch/URLS";
import axios from "axios";

const getSingleData = async (url) => {
  const data = await getData(url);
  return data.doc;
};

const getMultipleDetails = async (url) => {
  const data = await getData(url);
  return data.docs;
};

function base64ImageToFile(base64Image) {
  // Split the base64 string to remove the data URL scheme (optional, but common)
  const arr = base64Image.split(",");
  const mimeType = arr[0].match(/:(.*?);/)[1]; // Extract the MIME type from base64
  const byteString = atob(arr[1]); // Decode the base64 string

  // Create an array of bytes
  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the byteArray
  const blob = new Blob([byteArray], { type: mimeType });

  // Convert the Blob to a File object
  const file = new File([blob], "image.png", { type: mimeType });

  return file;
}

function filterOnFinishValues(values, isFormData) {
  if (isFormData) {
    const formData = new FormData();

    for (const key in values) {
      if (values[key]) {
        formData.append(key, values[key]);
      }
    }

    return formData;
  } else {
    const vals = {};
    for (const key in values) {
      if (values[key]) {
        vals[key] = values[key];
      }
    }

    return vals;
  }
}

function base64ToFile(base64String) {
  // Split the base64 string into parts
  const [header, data] = base64String.split(",");
  const mime = header.match(/:(.*?);/)[1]; // Extract the mime type

  // Decode base64 string to binary
  const binaryString = atob(data);

  // Convert binary string to Uint8Array
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([bytes], { type: mime });

  // Create a File object from the Blob
  return new File([blob], "image.jpeg", { type: mime });
}

function truncateString(str, length) {
  return str?.length > length ? str?.slice(0, length) + "..." : str;
}

const disableFutureDates = (current) => {
  return current && current > dayjs(); // Disables any date after today
};

function calculateAge(dob) {
  const birthDate = new Date(dob);
  const today = new Date();

  let ageYears = today.getFullYear() - birthDate.getFullYear();
  let ageMonths = today.getMonth() - birthDate.getMonth();

  // Adjust years and months if birth month has not occurred this year yet
  if (
    ageMonths < 0 ||
    (ageMonths === 0 && today.getDate() < birthDate.getDate())
  ) {
    ageYears--;
    ageMonths += 12;
  }

  // Calculate age as a decimal where months are represented as fractions of a year
  const ageInDecimal = ageYears + ageMonths / 12;

  return Math.floor(ageInDecimal); // Returns age rounded to 1 decimal place
}

const validateMobileNumber = (dict, lang) => {
  return (_, value) => {
    if (!(value * 1) && value) {
      return Promise.reject("Invalid Mobile Number!");
    }

    if (!value || value.length === 10) {
      return Promise.resolve();
    }

    return Promise.reject("Invalid Mobile Number!");
  };
};

const handlePrint = async (receipt) => {
  if (receipt) {
    printJS({ printable: receipt, type: "pdf", showModal: true });

    return true;
  }
};

const validateAadharNumber = (_, value) => {
  // const lang =  localStorage.getItem("lang");
  // const dict = langingPage[lang];

  if (!(value * 1) && value) {
    return Promise.reject("Invalid Aadhar number.");
  }

  if (!value || value.length === 12) {
    return Promise.resolve();
  }

  // return Promise.reject(dict.invalid_aadhar_number[lang]);
  return Promise.reject(
    "Invalid Aadhar number. It must be exactly 12 digits long."
  );
};

const smallFieldStyle = () => {
  return (
    <style>
      {`
        .ant-form-item-explain-error {
          margin-top: -8px !important;
        }
        .ant-form-item {
          margin-top: -14px !important;
        }
        .ant-form-item-label {
        margin-bottom: -6px !important}
      `}
    </style>
  );
};

async function getCroppedImg(imageSrc, crop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(URL.createObjectURL(blob));
    }, "image/jpeg");
  });
}

function createImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // CORS
    image.src = url;
  });
}

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Read the file as a data URL (Base64 string)
    reader.readAsDataURL(file);

    // On successful load
    reader.onload = () => resolve(reader.result);

    // On error
    reader.onerror = (error) => reject(error);
  });
};

async function urlToBase64(url) {
  try {
    // Fetch the file from the URL
    const response = await fetch(url);

    // Ensure the fetch was successful
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the file as a Blob
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result); // The result is the Base64 string
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(blob); // Read the blob as a data URL
    });
  } catch (error) {
    console.error("Error converting URL to Base64:", error);
    throw error;
  }
}

/**
 * Compress and resize the input image file.
 * @param {File} file - The image file to compress and resize.
 * @returns {Promise<File>} - The processed image file.
 */

/**
 * Resize the input image file to a maximum size of 100x100 pixels.
 * @param {File} file - The image file to resize.
 * @returns {Promise<File>} - The resized image file.
 */
const resizeImage = (file, setImage) => {
  return new Promise((resolve, reject) => {
    if (!file || !file.type.startsWith("image/")) {
      reject(new Error("Invalid file type. Please provide an image file."));
      return;
    }

    Resizer.imageFileResizer(
      file,
      500, // New width
      600, // New height
      "JPEG", // Output format
      100, // Quality (0-100)
      0, // Rotation (0 degrees)
      async (uri) => {
        const enhancedImage = await resizeAndAddLightOverlay(uri); // Wait for the enhancement
        setImage(enhancedImage);

        resolve(enhancedImage); // Resolve with the enhanced image
      },
      "base64" // Output format
    );
  });
};
const resizeAndAddLightOverlay = (
  base64Image,
  size = 500,
  lightColor = "rgba(255, 255, 255, 0.2)"
) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64Image;

    img.onload = () => {
      // Calculate aspect ratio
      const aspectRatio = img.width / img.height;
      const newWidth = size;
      const newHeight = size / aspectRatio;

      // Create canvas for resizing and overlay
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = newWidth;
      canvas.height = newHeight;

      // Resize and draw the image
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // Add light color overlay
      ctx.fillStyle = lightColor; // Light overlay color
      ctx.fillRect(0, 0, newWidth, newHeight); // Apply overlay

      // Return the final image as a Base64 string
      resolve(canvas.toDataURL("image/jpeg"));
    };
  });
};

// Usage

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    day: "numeric",
    month: "short",
  }).format(date);
};

const limitLength = (_, field, form, length) => {
  const value = form.getFieldValue(field) + "";

  if (value.length > length) {
    form.setFieldsValue({ [field]: value.substring(0, length) }); // Update state only if values are 3 or fewer
  }
};

const formatDateFunction = (date, hourMinuts) => {
  const hm = {
    hour: "2-digit",
    minute: "2-digit",
  };

  let options = {
    weekday: "short",
    year: "numeric",
    day: "numeric",
    month: "short",
  };

  if (hourMinuts) {
    options = { ...options, ...hm };
  }

  return new Date(date).toLocaleDateString("en-US", options);
};

const downloadLostReport = async (setLoading, reportName, uri, isPdf) => {
  try {
    let url = URLS.base_url + uri;

    const response = await axios.get(url, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionToken") || "",
      },
    });

    let blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    if (isPdf) blob = new Blob([response.data], { type: "application/pdf" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);

    if (isPdf) link.download = `${reportName}.pdf`;
    else link.download = `${reportName}.xlsx`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (response) {
      setLoading(false);
    }
  } catch (error) {
    message.error("Error downloading the report", error);
  }
};

const addRandomTime = (createdAt) => {
  const date = dayjs(createdAt);

  const randomHours = Math.floor(Math.random() * 3) + 2; // 2 to 5

  // Generate random minutes between 0 and 59
  const randomMinutes = Math.floor(Math.random() * 60);

  // Generate random seconds between 0 and 59
  const randomSeconds = Math.floor(Math.random() * 60);

  // Add the random time to the date
  const updatedDate = date
    .add(randomHours, "hour")
    .add(randomMinutes, "minute")
    .add(randomSeconds, "second");

  return updatedDate;
};

export {
  addRandomTime,
  downloadLostReport,
  handlePrint,
  formatDateFunction,
  limitLength,
  getSingleData,
  base64ImageToFile,
  filterOnFinishValues,
  base64ToFile,
  truncateString,
  disableFutureDates,
  calculateAge,
  validateMobileNumber,
  validateAadharNumber,
  smallFieldStyle,
  getMultipleDetails,
  getCroppedImg,
  fileToBase64,
  resizeImage,
  convertFileToBase64,
  formatDate,
};
