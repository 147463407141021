import React, { useEffect, useState } from "react";
import { getData, postData, postFaceDetection } from "../../Fetch/Axios";
import URLS from "../../Fetch/URLS";
import { Image, Spin } from "antd";
import DataTableModal from "../../search/lost_person/LostFoundModel";
import { resizeImage, truncateString } from "../../utils/commonFunctions";
import { langingPage } from "../../utils/dictionary";
import { useOutletContext } from "react-router";

const FaceDetectionResult = ({
  image,
  purpose = "found",
  baseUrl = URLS.found_photo_base_url,
  isLux,
  loading,
  setLoading,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const [lostPersonInfo, setLostPersonInfo] = useState({
    lost: [],
    found: [],
    accuracy: {},
  });

  const [percentage, setPercentage] = useState(50);

  const getMaxDetectionRes = async () => {
    setLoading(true);

    let baseString;

    const handleResizedImage = async (image) => {
      baseString = image;
    };

    await resizeImage(image, handleResizedImage);

    let singleRes;

    if (baseString) {
      const payload = {
        encoded_image: baseString.split("base64,")[1],
      };

      singleRes = await postData(payload, URLS.mx_search, false);

      if (singleRes?.docs) {
        singleRes = singleRes?.docs;
      }
    }

    if (
      singleRes &&
      singleRes?.searchedIdentities[0].identityConfidences?.length
    ) {
      singleRes = singleRes?.searchedIdentities[0].identityConfidences;

      let lostIds = [];
      let foundIds = [];
      let accuracy = {};

      for (const el of singleRes) {
        lostIds.push(el.identity.externalId);
      }

      let lostRes;
      let foundRes;

      let finalRes = [];

      if (lostIds?.length) {
        lostRes = await getData(URLS.getLostByIds + lostIds.join(","));

        const finalLost = lostRes?.docs || [];
        finalRes = [...finalRes, ...finalLost];
      }

      foundIds = lostIds;

      if (foundIds?.length) {
        foundRes = await getData(URLS.getFoundByIds + lostIds.join(","));
        const finalLost = foundRes?.docs || [];
        finalRes = [...finalRes, ...finalLost];
      }

      if (lostRes || foundRes) {

        setLostPersonInfo((prev) => {
          return {
            lost: finalRes || [],
            accuracy,
          };
        });

        setLoading(false);
      }
    } else {
      setLostPersonInfo(() => {
        return {
          lost: [],
          accuracy: {},
        };
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (image) {
      getMaxDetectionRes();
    } else {
      setLostPersonInfo(() => {
        return {
          lost: [],
          accuracy: {},
        };
      });
    }
  }, [image, percentage, isLux]);

  // useEffect(() => {
  //   if (model) {
  //     if (model === "l") {
  //       setModelPurpose(lostPersonInfo);
  //     } else {
  //       setModelPurpose(lostPersonInfo1);
  //     }
  //   }
  // }, [model, purpose, lostPersonInfo, lostPersonInfo1]);

  return (
    <Spin spinning={loading}>
      <div className="flex border gap-1 flex-wrap overflow-scroll ">
        <div className="w-full text-green-700 font-semibold text-center h-fit bg-[#ffd6e7] p-1 border">
          <span className="">{dict.image_based_matches[lang]}</span>
        </div>

        {lostPersonInfo.lost.length === 0 && (
          <span className="w-full flex justify-center items-center h-64">
            {dict.no_records_found[lang]}
          </span>
        )}

        {lostPersonInfo.lost.length !== 0 && (
          <div className="h-64 gap-2 bg-[#fff0f6] grid grid-cols-1">
            {lostPersonInfo.lost.length !== 0 && (
              <div className="h-64 gap-2 bg-[#fff0f6] grid grid-cols-1">
                {lostPersonInfo.lost.map((el, index) => {
                  return (
                    <div
                      key={el.photo + index}
                      className="bg-green-100 flex flex-col h-fit rounded-t-md"
                    >
                      <div
                        key={el}
                        className="flex overflow:hidden flex-col text-center  rounded-t-md"
                      >
                        <Image
                          className="rounded-t-md overflow:hidden"
                          src={
                            (el.complaint_number.startsWith("L")
                              ? URLS.lost_photo_base_url
                              : URLS.found_photo_base_url) + el.photo
                          }
                          alt=""
                        />
                      </div>

                      <div
                        className="p-1 w-full text-center text-base flex flex-col"
                        onClick={() => {
                          setIsModalVisible(() => el);
                        }}
                      >
                        <div className="p-1 w-full text-center ">
                          {truncateString(
                            el.first_name + " " + el.last_name,
                            20
                          )}
                        </div>
                        <div className="p-1 -mt-2 w-full text-center ">
                          {el?.complaint_number}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
      <DataTableModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      ></DataTableModal>
    </Spin>
  );
};

export default FaceDetectionResult;
