import React from "react";
import CommonDivider from "../../commonComponents/CommonDivider";
import CommonTable from "../../commonComponents/CommonTable";
import { handlePrint } from "../../utils/commonFunctions";
import { Button, Form, Image, Input, message, Select } from "antd";
import { postData } from "../../Fetch/Axios";
import CommonSearchForm from "../../commonComponents/CommonSearchForm";
import { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import URLS from "./../../Fetch/URLS";
import { langingPage } from "../../utils/dictionary";

const CallingStatus = () => {
  const [searchQuery, setSearchQuery] = useState();
  const [form] = Form.useForm();
  const [key, setKey] = useState(1);
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const form1 = Form.useForm();
  let formSearch = form1[0];

  const onFinish = async (vals) => {
    const isFound =
      vals.callingStatus.status.includes("Telephonic traced") ||
      vals.callingStatus.status.includes("Self");

    if (isFound) {
      vals.isFound = isFound;
    }

    const data = await postData(vals, URLS.calling_status);

    // const selectOptions = [
    //   {
    //     label: "Telephonic traced",
    //     value: "Telephonic traced - टेलीफोनिक ट्रेस",
    //   },
    //   { label: "Not Found", value: "Not Found - नहीं मिला" },
    //   { label: "Not reachable", value: "Not reachable - संपर्क नहीं हो रहा" },
    //   {
    //     label: "Not responding",
    //     value: "Not responding - प्रतिक्रिया नहीं दे रहा",
    //   },
    //   { label: "Switch off", value: "Switch off - बंद है" },
    //   { label: "Wrong Number", value: "Wrong Number - गलत नंबर" },
    //   { label: "Hospitalized", value: "Hospitalized - अस्पताल में भर्ती" },
    //   { label: "Self", value: "Self - स्वयं" },
    // ];

    if (
      data &&
      (vals.callingStatus.status.includes("Telephonic traced") ||
        vals.callingStatus.status.includes("Self"))
    ) {
      setKey((prev) => {
        return prev + 1;
      });
    }
  };

  const columns = [
    {
      title: dict.ticket_number[lang],
      dataIndex: "complaint_number",
      key: "complaint_number",
      fixed: "left",
      width: 120,
    },
    {
      title: dict.center[lang],
      dataIndex: "center_id",
      key: "center_id",
      render: (val) => {
        return val?.name || "-";
      },
    },
    {
      title: dict.first_name[lang],
      dataIndex: "first_name",
      key: "name",
    },
    {
      title: dict.last_name[lang],
      dataIndex: "last_name",
      key: "name",
    },
    {
      title: dict.photo[lang],
      dataIndex: "photo",
      key: "photo",
      width: 90,
      render: (photo) => {
        return photo ? (
          <Image width={60} src={URLS.lost_photo_base_url + photo}></Image>
        ) : (
          "-"
        );
      },
    },
    {
      title: dict.gender[lang],
      dataIndex: "gender",
      key: "gender",
    },

    {
      title: dict.age[lang],
      dataIndex: "age",
      key: "age",
    },

    {
      title: dict.complainant_relation[lang],
      dataIndex: "complainant_relation",
      key: "complainant_relation",
      width: 130,
    },
    {
      title: dict.complainant_name[lang],
      dataIndex: "complainant_name",
      key: "complainant_name",
      width: 150,
    },
    {
      title: dict.complainant_mob_number[lang],
      dataIndex: "complainant_mob_number",
      key: "complainant_mob_number",
      width: 200,
    },
    {
      title: dict.receipt[lang],
      dataIndex: "receipt",
      key: "receipt",
      render: (el) => {
        return el ? (
          <div
            onClick={() => handlePrint(URLS.receipt + el)}
            className="text-blue-400"
          >
            Receipt
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: dict.address[lang],
      dataIndex: "address",
      key: "address",
      width: 200,
      render: (val) => {
        return val || "-";
      },
    },
    {
      title: dict.status[lang],
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (_, el) => {
        return (
          <Form.Item
            className="w-full"
            name={el.complaint_number + "_status"}
            rule={[{ required: true, message: "Status is required" }]}
            noStyle
          >
            <Select
              className="w-full"
              placeholder="Status"
              size="small"
              options={[
                {
                  label: "Telephonic traced",
                  value: "Telephonic traced",
                },
                { label: "Not Found", value: "Not Found" },
                {
                  label: "Not reachable",
                  value: "Not reachable",
                },
                {
                  label: "Not responding",
                  value: "Not responding",
                },
                { label: "Switch off", value: "Switch off" },
                { label: "Wrong Number", value: "Wrong Number" },
                {
                  label: "Hospitalized",
                  value: "Hospitalized",
                },
                { label: "Self", value: "Self" },
              ]}
            ></Select>
          </Form.Item>
        );
      },
    },
    {
      title: dict.remark[lang],
      dataIndex: "remark",
      key: "status",
      width: 200,
      render: (_, el) => {
        return (
          <Form.Item
            rule={[{ required: true, message: "Remark is required" }]}
            className="w-full"
            name={el.complaint_number + "_remark"}
            noStyle
          >
            <Input className="w-full" placeholder="Remark" size="small"></Input>
          </Form.Item>
        );
      },
    },
    {
      title: dict.update[lang],
      dataIndex: "update",
      key: "update",
      render: (_, el) => {
        return (
          <Button
            size="small"
            className="bg-green-300"
            onClick={() => {
              const status = form.getFieldValue(
                el.complaint_number + "_status"
              );
              const remark = form.getFieldValue(
                el.complaint_number + "_remark"
              );

              if (status && remark) {
                const vals = {
                  complaint_number: el.complaint_number,
                  callingStatus: { remark, status },
                };

                onFinish(vals);

                form.resetFields([
                  el.complaint_number + "_remark",
                  el.complaint_number + "_status",
                ]);
              } else {
                message.info("Status and Remark fields are required!");
              }
            }}
          >
            Update
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <CommonDivider
        label={dict.calling_status[lang]}
        other={
          <Link to="/calling-history" className="no-underline">
            <Button className="flex bg-gray-200 text-black font-semibold mb-1">
              {dict.calling_history[lang]}
            </Button>
          </Link>
        }
      ></CommonDivider>

      <div>
        <CommonSearchForm
          form={formSearch}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          fields={[
            { name: "complaint_number", label: dict.ticket_number[lang] },
          ]}
        ></CommonSearchForm>

        <div key={key}>
          <Form form={form}>
            <CommonTable
              uri={"calling_status"}
              columns={columns}
              reactBaseUrl={"calling-status"}
              extraParams={searchQuery} // + "&lastDay=10"
              scroll={{ x: 2000, y: 400 }}
              extraQeuries={true}
            ></CommonTable>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CallingStatus;
