import React, { useEffect, useState } from "react";
import PrayagrajMap from "./PrayagrajMap";
import CaseStatistics from "./CaseStatistics";
import { getData } from "../Fetch/Axios";
import URLS from "../Fetch/URLS";
import { Button } from "antd";
import ComparisonChart from "./ComparisonChart";
import Statistics from "./Statistics";
import GenderGroupBar from "./GenderGroupBar";

const Dashboard = () => {
  const [details, setDetails] = useState();

  const [isComparisonModelOpen, setIsComparisonModelOpen] = useState(false);

  const getDetails = async () => {
    const data = await getData(URLS.dashboard);

    if (data) {
      setDetails(data.docs);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div>
      <Statistics details={details?.todaysStatistics}></Statistics>
      <CaseStatistics statistics={details?.totalStatistics}></CaseStatistics>
      <style>
        {`
            .ant-table-body {
                min-height: 350px !important;
              }import GenderGroupBar from './GenderGroupBar';

            `}
      </style>

      <div className="-mt-5">
        <div className="relative  flex w-full justify-end top-10 right-4">
          <Button
            className="rounded-none cursor-pointer"
            onClick={() => {
              setIsComparisonModelOpen(true);
            }}
          >
            View Records
          </Button>
        </div>

        <ComparisonChart
          // details={details?.lastThreeDaysStatistics}
          isComparisonModelOpen={isComparisonModelOpen}
          setIsComparisonModelOpen={setIsComparisonModelOpen}
        ></ComparisonChart>
      </div>

      <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-3">
        <div className="shadow-sm ">
          <div className="px-2 text-lg font-semibold -mb-3">
            Gender-wise Comparison
          </div>
          <GenderGroupBar URL={URLS.get_weeks_statistics}></GenderGroupBar>
        </div>{" "}
        <div className="shadow-sm ">
          <div className="px-2 text-lg font-semibold -mb-3">
            Age Group Comparison
          </div>
          <GenderGroupBar
            URL={URLS.get_weeks_age_statistics}
            age={["Chidren", "Adults", "Senior Citizens"]}
          ></GenderGroupBar>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-7 lg:grid-cols-6 xl:grid-cols-4 gap-3">
        {/* <div className="md:col-span-3 lg:col-span-2 xl:col-span-1 h-full">
          <RecentLostAndFoundCases
            details={details?.resent_lost_and_found_cases}
          ></RecentLostAndFoundCases>
        </div> */}
      </div>

      <PrayagrajMap></PrayagrajMap>
    </div>
  );
};

export default Dashboard;
