import { Button, Form, Input, InputNumber, message } from "antd";
import React, { useEffect, useState } from "react";
import { patchData, postData } from "../../Fetch/Axios";
import URLS from "../../Fetch/URLS";

import { Link } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import { langingPage } from "../../utils/dictionary";
import NumericCaptcha from "../NumericCaptcha";

function validatePassword(value) {
  if (value.length < 8) {
    return false;
  }

  // Check for uppercase letter
  if (!/[A-Z]/.test(value)) {
    return false;
  }

  // Check for lowercase letter
  if (!/[a-z]/.test(value)) {
    return false;
  }

  // Check for number
  if (!/\d/.test(value)) {
    return false;
  }

  // Check for special character
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
    return false;
  }

  // All checks passed
  return true;
}

const ChangeYourPassword = () => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const handleOnInput = (id) => {
    const captRes = document.querySelector(id);
    if (captRes) {
      captRes.innerText = "";
    }
  };

  // handle captchat
  const [isInvalidCaptcha, setisInvalidCaptcha] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [userInput, setUserInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dict = langingPage;

  const handleValidate = () => {
    if (userInput !== captcha) {
      setErrorMessage("Invalid Captcha!");
      setisInvalidCaptcha(true);
      return false;
    } else {
      return true;
    }
  };

  const onFinish = async (values) => {
    const isCorrect = handleValidate();
    if (isCorrect) {
      setUserInput("");
    }

    const phone = localStorage.getItem("mob");

    if (!phone) {
      message.info("Something went wrong!");
      return;
    }

    if (isCorrect) {
      setLoading(true);
      const res = await patchData(values, URLS.resetPassword + phone);
      if (res) {
        setLoading(false);
        setDone(true);
      } else {
        setLoading(false);
      }

      if (res) {
        setisInvalidCaptcha(true);
        setErrorMessage("");
      } else {
        setUserInput("");
        setErrorMessage("");
        setisInvalidCaptcha(true);
      }
    }
  };

  return (
    <>
      {!done ? (
        <div>
          <div className="flex flex-col text-start font-semibold sm:text-2xl w-full justify-center md:items-center text-green-800 ">
            Maha Kumbh Mela 2025
          </div>

          <div>Check message for a otp to reset your password.</div>

          <Form
            onFinish={onFinish}
            className="max-w-96 relative border border-gray-200 h-fit p-3 mt-3"
            layout="vertical"
          >
            <div className="text-xl text-center mb-3 font-semibold">
              Change Password
            </div>
            <Form.Item
              name="otp"
              rules={[
                { required: true, message: "Pease OTP!" },
                {
                  min: 6,
                  message: "Please ener 6 digit OTP!",
                },
              ]}
              label={<div className="text-sm">OTP</div>}
            >
              <Input prefix={<LockOutlined />} placeholder="Enter OTP"></Input>
            </Form.Item>

            <Form.Item
              className="w-full"
              name="password"
              rules={[
                { required: true, message: "Pease enter password!" },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || validatePassword(getFieldValue("password"))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character."
                      )
                    );
                  },
                }),
              ]}
              label={<div className="text-sm">Password</div>}
            >
              <Input.Password
                className="w-full "
                prefix={<LockOutlined />}
                placeholder="Enter password"
              ></Input.Password>
            </Form.Item>

            <Form.Item
              name="passwordConfirm"
              rules={[
                { required: true, message: "Pease enter password confirm!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match!"));
                  },
                }),
              ]}
              label={<div className="text-sm">Confirm Password</div>}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Enter Your Phone Number"
              ></Input.Password>
            </Form.Item>

            <NumericCaptcha
              setisInvalidCaptcha={setisInvalidCaptcha}
              isInvalidCaptcha={isInvalidCaptcha}
              captcha={captcha}
              setCaptcha={setCaptcha}
              userInput={userInput}
              setUserInput={setUserInput}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            ></NumericCaptcha>

            <Form.Item noStyle>
              <div className="flex justify-end w-full">
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="bg-green-500 text-white font-semibold w-full mt-3"
                >
                  Update Password
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div className="max-w-96 relative border border-gray-200 h-fit p-3 text-center">
          <div>Your password has been successfully reset!</div>
          <div className="w-full mt-3 flex items-center justify-center">
            <Link to="/login" className="w-full">
              <Button className="w-full">Return to sign in</Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangeYourPassword;
