import React from "react";
import { Form, Input, Button, Select, notification } from "antd";
import { generateSearchQuery } from "../utils/getSearchQuery";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";

const CommonSearchForm = ({
  fields,
  dropFields,
  setSearchQuery,
  form,
  dropdown,
}) => {
  const [api, contextHolder] = notification.useNotification({ top: 100 });
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: dict.note[lang],
      duration: 7,
      description: dict.please_enter_info_to_search[lang],
    });
  };

  const onFinishForm = (values) => {
    if (values?.dateRange && values?.dateRange[0]) {
      let startDate = new Date(values.dateRange[0]);
      let endDate = new Date(values.dateRange[1]);

      values.startDate = `${startDate.getFullYear()}-${String(
        startDate.getMonth() + 1
      ).padStart(2, "0")}-${String(startDate.getDate()).padStart(2, "0")}`;

      values.endDate = `${endDate.getFullYear()}-${String(
        endDate.getMonth() + 1
      ).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;

      delete values.dateRange;
    }

    if (values?.country_id) {
      values.country = values.country_id;
      delete values.country_id;
    }

    if (values?.state_id) {
      values.state = values.state_id;
      delete values.state_id;
    }

    if (values?.city_id) {
      values.city = values.city_id;
      delete values.city_id;
    }

    const searchParams = generateSearchQuery(values);

    setSearchQuery(searchParams);
  };

  return (
    <div className="border p-2 mb-3">
      <Form form={form} layout="vertical" onFinish={onFinishForm} key="1">
        <div className="grid flex-wrap gap-2 grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 items-center ">
          {fields &&
            fields.map((el) => {
              return (
                <Form.Item
                  key={el.name}
                  noStyle
                  name={el.name}
                  layout="vertical"
                  className=""
                >
                  <Input
                    placeholder={el.label}
                    className="rounded-none w-full"
                  />
                </Form.Item>
              );
            })}

          {dropFields &&
            dropFields.map((el, key) => {
              return (
                <Form.Item
                  key={el.name}
                  noStyle
                  name={el.name}
                  layout="vertical"
                >
                  <Select
                    placeholder={el?.placeholder}
                    className="rounded-none"
                    allowClear
                  >
                    {el.options.map((opt) => (
                      <Option key={opt.value} value={opt.name}>
                        {opt.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            })}
          {dropdown}
          <div className="flex w-full  gap-2 ml-auto  col-span-2 sm:col-span-2 md:col-span-4 lg:col-span-5 justify-end">
            <Form.Item noStyle>
              <Button
                type="primary"
                className="rounded-none bg-orange-300 text-black"
                onClick={() => {
                  if (form) {
                    form.resetFields();
                  }
                  setSearchQuery("&");
                }}
              >
                {dict.reset[lang]}
              </Button>
            </Form.Item>

            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                className="rounded-none bg-green-300 text-black "
              >
                {dict.search[lang]}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>

      {contextHolder}
    </div>
  );
};

export default CommonSearchForm;
const { Option } = Select;
