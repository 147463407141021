import React, { useEffect, useState } from "react";
import CommonSelect from "../commonComponents/CommonSelect";
import { message, Space } from "antd";
import axios from "axios";
import URLS from "../Fetch/URLS";

const languages = [
  { value: "bn", label: "Bengali" },
  { value: "en", label: "English" },
  { value: "gu", label: "Gujarati" },
  { value: "hi", label: "Hindi" },
  { value: "kn", label: "Kannada" },
  { value: "ml", label: "Malayalam" },
  { value: "mr", label: "Marathi" },
  { value: "or", label: "Odia" },
  { value: "pa", label: "Punjabi" },
  { value: "sa", label: "Sanskrit" },
  { value: "ta", label: "Tamil" },
  { value: "te", label: "Telugu" },
  { value: "ur", label: "Urdu" },
];

const gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

const getSourceLanguages = () => {
  return languages;
};

const LanguageSelector = ({
  setSourceLanguage,
  setTargetLanguage,
  setGender,
  onlyText,
}) => {
  const [langs, setLangs] = useState({
    list: [],
    target: {},
    modelServiceId: {},
  });

  const [localSourceLnag, setLocalSourceLnag] = useState();
  const [targetLangs, setTargetLangs] = useState([]);

  useEffect(() => {
    const getLangs = async () => {
      const url = URLS.bhashini;

      const payload = {
        url: "https://meity-auth.ulcacontrib.org/ulca/apis/v0/model/getModelsPipeline",
        pipelineTasks: [
          {
            taskType: "asr",
          },
          {
            taskType: "translation",
          },
          {
            taskType: "tts",
          },
        ],
        pipelineRequestConfig: {
          pipelineId: "64392f96daac500b55c543cd",
        },
      };

      let result;

      try {
        result = await axios.post(URLS.base_url + url, payload);

        result = result?.data?.data;
      } catch (error) {
        console.log(error);
      }

      if (result) {
        const target = {};
        const list = [];

        result.languages.forEach((element) => {
          target[element.sourceLanguage] = element.targetLanguageList;
          list.push(element.sourceLanguage);
        });
        const modelServiceId = {};

        result.pipelineResponseConfig
          .find((el) => el.taskType === "asr")
          .config.forEach((el) => {
            modelServiceId[el.language.sourceLanguage] = {
              serviceId: el.serviceId,
              modelId: el.modelId,
            };
          });

        setLangs(() => {
          return { list, target, modelServiceId };
        });
      } else {
        message.info("Something Went Wrong!");
      }
    };

    getLangs();
  }, []);

  useEffect(() => {
    if (localSourceLnag) {
      const targeList = languages.filter((el) => el.value !== localSourceLnag);
      setTargetLangs(targeList);
    }
  }, [localSourceLnag, langs]);

  return (
    <div>
      <Space wrap>
        <CommonSelect
          setValue={setSourceLanguage}
          setLocalSourceLnag={setLocalSourceLnag}
          label={"Source Language"}
          input={langs}
          isSourceLang={true}
          options={getSourceLanguages()}
        ></CommonSelect>
        <CommonSelect
          setValue={setTargetLanguage}
          label={"Target Language"}
          options={targetLangs}
        ></CommonSelect>
        <CommonSelect
          setValue={setGender}
          label={"Gender"}
          options={gender}
          name="gender"
          initialValues={{ gender: "female" }}
        ></CommonSelect>
      </Space>
    </div>
  );
};

export default LanguageSelector;
