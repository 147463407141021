import {
  AudioMutedOutlined,
  AudioOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import Draggable from "react-draggable";
import Converter from "../BhashiniApi/Converter";
import { Button, FloatButton, Tooltip } from "antd";
import OnlyRecorder from "../BhashiniApi/OnlyRecorder";

const AudioNotepad = () => {
  const [recording, setRecording] = useState(false);
  const [converterDetector, setConverterDetector] = useState("c");

  return (
    <div>
      <FloatButton
        onClick={() => setRecording(!recording)}
        type="primary"
        icon={recording ? <AudioOutlined /> : <AudioMutedOutlined />}
        style={{
          insetInlineEnd: 20,
          width: "50px",
          height: "50px",
        }}
      >
        <Tooltip title={recording ? "Stop Recording" : "Start Recording"} />
      </FloatButton>
      {recording && (
        <Draggable
          enableUserSelectHack={false}
          handle=".draggable-handle"
          cancel="textarea, button, .ant-select, .ant-dropdown, .ant-text-area, .ant-close-recorder"
        >
          <div
            className="draggable-handle"
            style={{
              position: "fixed",
              top: "100px",
              right: "50px",
              width: "350px",
              padding: "10px",
              background: "#fff",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              border: "1px solid blue",
              zIndex: 1000,
              touchAction: "none",
            }}
          >
            <div className="font-semibold flex  justify-between">
              <span>Language Exchange</span>

              <div
                className="ant-close-recorder"
                onClick={() => {
                  setRecording(!recording);
                }}
              >
                <CloseCircleOutlined className="text-xl"></CloseCircleOutlined>
              </div>
            </div>
            <hr className="mt-0 mb-1" />
            <div
              style={{
                touchAction: "auto",
                userSelect: "text",
              }}
              className="ant-text-area"
            >
              <div className="flex gap-1">
                <Button
                  onClick={() => {
                    setConverterDetector("c");
                  }}
                  className={
                    converterDetector === "c" ? "bg-orange-300" : "bg-green-100"
                  }
                >
                  Converter
                </Button>
                <Button
                  onClick={() => {
                    setConverterDetector("d");
                  }}
                  className={
                    converterDetector === "d" ? "bg-orange-300" : "bg-green-100"
                  }
                >
                  Detector
                </Button>
              </div>
              <hr className="mt-1 mb-1" />

              {converterDetector === "d" ? (
                <OnlyRecorder></OnlyRecorder>
              ) : (
                <Converter></Converter>
              )}
            </div>
          </div>
        </Draggable>
      )}
    </div>
  );
};

export default AudioNotepad;
