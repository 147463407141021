import React, { useState } from "react";
import { Form, Input } from "antd";
import CommonSelectOption from "./CommonSelectOption";
const selectOptions = [
  { label: "Telephonic traced", value: "Telephonic traced - टेलीफोनिक ट्रेस" },
  { label: "Not Found", value: "Not Found - नहीं मिला" },
  { label: "Not reachable", value: "Not reachable - संपर्क नहीं हो रहा" },
  {
    label: "Not responding",
    value: "Not responding - प्रतिक्रिया नहीं दे रहा",
  },
  { label: "Switch off", value: "Switch off - बंद है" },
  { label: "Wrong Number", value: "Wrong Number - गलत नंबर" },
  { label: "Hospitalized", value: "Hospitalized - अस्पताल में भर्ती" },
  { label: "Self", value: "Self - स्वयं" },
];

const TracedHandover = ({ form }) => {
  const [type, setType] = useState();

  return (
    <div className="flex gap-3">
      <div className=" mt-3 grid w-full grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2 h-fit">
        <Form.Item label={"Any Details"} name={"receiver_any_details"}>
          <Input size="small" placeholder={"Any Details"} />
        </Form.Item>
        <div className="flex">
          <Form.Item
            label={"Status"}
            name={"receiver_status"}
            rules={[{ required: true, message: "Please Select Status" }]}
          >
            <CommonSelectOption
              form={form}
              name="receiver_status"
              setType={setType}
              placeholder={"Status"}
              selectOption={selectOptions}
            ></CommonSelectOption>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default TracedHandover;
