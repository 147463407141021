import React from "react";
import { Menu, Button, Select } from "antd";

const LanguageDropdown = ({ lang, setLang }) => {
  const handleMenuClick = (e) => {
    setLang(e);
  };

  const menu = [
    { label: "हिंदी", value: "hi" },
    { label: "English", value: "en" },
  ];

  return (
    <Select
      size="small"
      options={menu}
      onChange={handleMenuClick}
      defaultValue={lang}
    >
      <Button>Select Language</Button>
    </Select>
  );
};

export default LanguageDropdown;
