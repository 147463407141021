import React, { useState } from "react";
import { Form, Radio } from "antd";
import { langingPage } from "../../utils/dictionary";
import { useOutletContext } from "react-router";

const SourceRadio = () => {
  const [value, setValue] = useState("");
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const pointOfSourceOptions = [
    {
      value: "Artificial Intelligence",
      label: dict.ai[lang],
    },
    {
      value: "Announcement/Bhashini",
      label: dict.announcementB[lang],
    },
    { value: "TV", label: dict.display[lang] },
    { value: "Social Media", label: dict.social[lang] },
    { value: "Other", label: dict.other[lang] },
    {
      value: "Telephonic Trace",
      label: dict.telephonic_trace_or_self[lang],
    },
  ];

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <Form.Item
        name="receiver_point_of_source"
        label={dict.point_of_source[lang]}
        rules={[
          {
            required: true,
            message: dict.point_of_source_required[lang],
          },
        ]}
      >
        <Radio.Group onChange={onChange} value={value} className="flex gap-2">
          <div className="flex gap-2 flex-wrap">
            {pointOfSourceOptions.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </div>
        </Radio.Group>
      </Form.Item>

      {/* <Form.Item
        name="foundBy"
        label={dict.foundBy[lang]}
        rules={[
          {
            required: true,
            message: dict.point_of_source_required[lang],
          },
        ]}
      >
        <Radio.Group onChange={onChange} value={value}>
          <div className="flex gap-2">
            {pointOfSourceOptions.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </div>
        </Radio.Group> */}
      {/* </Form.Item> */}
    </div>
  );
};

export default SourceRadio;
