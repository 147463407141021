import React, { useEffect, useState } from "react";
import img from "../../../assets/dummy.png";
import { HomeOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import URLS from "../../../Fetch/URLS";
import ChangePassword from "./ChangePassword";
import { Button } from "antd";
import { Link } from "react-router-dom";

const Profile = () => {
  const [userDetails, setUserDetails] = useState({
    name: "-",
    phone: "-",
    mail: "-",
    role: "-",
    address: "-",
    photo: img,
  });

  useEffect(() => {
    let userDetails = localStorage.getItem("sessionData");

    if (userDetails) {
      userDetails = JSON.parse(userDetails);
      setUserDetails(() => {
        return {
          name: userDetails.name,
          role: userDetails.role.name,
          phone: userDetails.phone,
          mail: userDetails.email,
          address: userDetails.address,
          photo: URLS.user_pho_base_url + userDetails.photo + "",
        };
      });
    }
  }, []);

  return (
    <div className="mt-20 shadow-md bg-white flex gap-3 flex-col sm:flex-row">
      <div className="relative w-52 h-52 -top-16 p-2 flex bg-white  ml-3 rounded-full border justify-center overflow-hidden">
        <img
          src={userDetails.photo}
          alt={userDetails.photo}
          className="overflow-hidden w-48 h-48 rounded-full "
        />
      </div>
      <div className=" flex gap-2 flex-col ml-3 relative -mt-16 sm:mt-3 p-2">
        <div className="text-xl font-semibold ">Name: {userDetails.name}</div>
        <div className="">Role: {userDetails.role}</div>
        <div className="">
          <MailOutlined></MailOutlined> {userDetails.mail}
        </div>
        <div className="">
          <PhoneOutlined /> {userDetails.phone}
        </div>
        <div className="">
          <HomeOutlined /> {userDetails.address}
        </div>
        <div>
          {/* <Link to="/change-password">
            <Button className="bg-orange-400 text-violet-900 font-semibold hover:text-black">
              Change Password
            </Button>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Profile;
