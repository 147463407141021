import React from "react";
import { QRCodeSVG } from "qrcode.react";
import {
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  RightOutlined,
} from "@ant-design/icons";

const Footer = ({ dict, lang }) => {
  return (
    <div className="text-white">
      <div className="grid grid-cols-2  sm:grid-cols-3 md:grid-cols-3 justify-between  gap-10 gap-y-8  lg:grid-cols-3">
        <div className="flex text-sm flex-col w-full items-start">
          <div>
            <div className="font-semibold mb-2 text-yellow-300 text-lg">
              {dict.contact[lang]}
            </div>
          </div>
          <div className="w-full font-semibold">
            <div className="flex gap-2">
              <div className="font-semibold">
                <EnvironmentOutlined className="text-white-400 text-lg" />{" "}
              </div>
              <div className="break-words w-full">
                {dict.address_footer[lang]}
              </div>
            </div>

            <div className="flex gap-2">
              <div className="font-semibold">
                <MailOutlined className="text-white-400 text-lg" />{" "}
              </div>
              <div className="break-words w-full inline-block">
                mahakumbh25@gmail.com
              </div>
            </div>

            <div className="flex gap-2">
              <div className="font-semibold">
                <PhoneOutlined className="text-white-400" />{" "}
              </div>
              <div className="break-words w-full inline-block">
                03369028124{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start md:items-center">
          <div>
            <div className=" font-semibold mb-2 text-yellow-300 text-lg">
              {dict.emmergency_no[lang]}
            </div>
            <div className="flex flex-col font-semibold text-sm">
              <span>
                <RightOutlined /> {dict.police[lang]} : 112
              </span>
              <span>
                <RightOutlined /> {dict.ambulance[lang]} : 108{" "}
              </span>
              <span>
                <RightOutlined /> {dict.helpline[lang]} : 1920
              </span>
              <span>
                <RightOutlined /> {dict.fire[lang]} : 101
              </span>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col items-start md:items-center">
          <div>
            <div className=" font-semibold mb-2 text-yellow-300 text-lg">
              {dict.web_links[lang]}
            </div>
            <div className="flex flex-col text-sm font-semibold">
              <span>
                <RightOutlined /> {dict.up_police[lang]}
              </span>
              <span>
                <RightOutlined /> {dict.faq[lang]}
              </span>
              <span>
                <RightOutlined /> {dict.community_forum[lang]}
              </span>
              <span>
                <RightOutlined /> {dict.technical_support[lang]}
              </span>
              <span>
                <RightOutlined /> {dict.citizen_feedback[lang]}
              </span>
            </div>
          </div>
        </div> */}

        <div className="flex flex-col items-start md:items-center">
          <div>
            <div className=" font-semibold mb-2 text-yellow-300 text-lg">
              {dict.scan_qr[lang]}
            </div>
            <div className="flex flex-col w-fit p-2 bg-violet-200 rounded-lg">
              <QRCodeSVG
                size={70}
                value={"https://kumbhlostandfound.in/kumbh-v2/"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
