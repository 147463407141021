import React, { useEffect, useState } from "react";
import URLS from "../Fetch/URLS";
import { Image } from "antd";
import { truncateString } from "../utils/commonFunctions";
import { getData } from "../Fetch/Axios";

const TVConfiguration2 = () => {
  const [presentingDoc, setPresentingDoc] = useState({
    first_name: "",
    complaint_number: "",
    last_name: "",
    name_hindi: "",
    age: "",
    attire: "",
    lost_date: "",
  });

  const [presentingDocs, setPresentingDocs] = useState({
    lostPersons: { docs: [], page: 0 },
    foundPersons: { docs: [], page: 0 },
  });

  const [presentingTitle, setPresentingTitle] = useState("खोये हुए व्यक्ति");

  const [completed, setCompleted] = useState(false);
  const [callerCount, setCallerCount] = useState(1);

  useEffect(() => {
    const getPresentingDocs = async () => {
      const getDetails = async (url) => {
        const data = await getData(url);
        return data;
      };

      let prLostPage = presentingDocs.lostPersons.page;
      let prFoundPage = presentingDocs.foundPersons.page;

      if (
        prLostPage === 1 &&
        prFoundPage === 1 &&
        presentingDocs.lostPersons.docs.length === 0 &&
        presentingDocs.foundPersons.docs.length === 0
      ) {
        return;
      }

      let lostDocs;
      let foundDocs;

      if (
        prLostPage === 0 ||
        (prLostPage >= 1 && presentingDocs.lostPersons.docs.length === 0)
      ) {
        lostDocs = await getDetails(URLS.lost_search + "&limit=8&page=" + 1);
      } else if (
        prLostPage >= 1 &&
        presentingDocs.lostPersons.docs.length !== 0
      ) {
        lostDocs = await getDetails(
          URLS.lost_search + "&limit=8&page=" + (prLostPage * 1 + 1)
        );
      }

      if (
        prFoundPage === 0 ||
        (prLostPage >= 1 && presentingDocs.foundPersons.docs.length === 0)
      ) {
        foundDocs = await getDetails(URLS.found_search + "&limit=4&page=" + 1);
      } else if (
        prFoundPage >= 1 &&
        presentingDocs.foundPersons.docs.length !== 0
      ) {
        foundDocs = await getDetails(
          URLS.found_search + "&limit=4&page=" + (prFoundPage * 1 + 1)
        );
      }

      let final = {
        lostPersons: { docs: [], page: 0 },
        foundPersons: { docs: [], page: 0 },
      };

      if (lostDocs) {
        final = {
          ...final,
          lostPersons: {
            key: Math.random(),
            docs: lostDocs.docs,
            page: lostDocs.pagination.page,
          },
        };
      }

      if (foundDocs) {
        final = {
          ...final,
          foundPersons: {
            key: Math.random(),
            docs: foundDocs.docs,
            page: foundDocs.pagination.page,
          },
        };
      }

      setPresentingDocs(() => {
        return { ...final };
      });
    };

    if (completed || callerCount === 1) {
      getPresentingDocs();
      setCompleted(false);
    }

    setCallerCount(2);
  }, [completed]);

  const [completedLosts, setCompletedLosts] = useState(false);

  function foundDocsSetter() {
    let countFound = 0;
    let intr2;

    if (presentingDocs.foundPersons.docs.length !== 0) {
      intr2 = setInterval(() => {
        if (countFound === 0) {
          setPresentingTitle("पाये हुए व्यक्ति की घोषणा");
        }

        if (countFound >= presentingDocs.foundPersons.docs.length) {
          setCompleted(true);
          clearInterval(intr2);
        } else if (presentingDocs.foundPersons.docs[countFound]) {
          setPresentingDoc({
            key: Math.random(),
            ...presentingDocs.foundPersons.docs[countFound],
          });

          countFound += 1;
        }
      }, presentingDocs.foundPersons.docs.length * 3000);
    }
  }

  useEffect(() => {
    if (
      presentingDocs.lostPersons.docs.length >= 1 ||
      presentingDocs.foundPersons.docs.length >= 1
    ) {
      let interval;

      if (presentingDocs.lostPersons.docs.length !== 0) {
        let count = 0;

        interval = setInterval(() => {
          if (count === 0) {
            setPresentingTitle("खोये हुए व्यक्ति की घोषणा");
          }

          if (count >= presentingDocs.lostPersons.docs.length) {
            if (presentingDocs.foundPersons.docs.length > 0) {
              setCompletedLosts(true);
              foundDocsSetter();
            } else {
              setCompleted(true);
            }

            clearInterval(interval);
          } else {
            setPresentingDoc({
              key: Math.random(),
              ...presentingDocs.lostPersons.docs[count],
            });
          }

          count += 1;
        }, 3000);
      } else if (presentingDocs.foundPersons.docs.length > 0) {
        setCompletedLosts(true);
        foundDocsSetter();
      } else {
        setCompleted(true);
      }
    }
  }, [presentingDocs]);

  return (
    <div>
      <div className="bg-gray-200 min-h-screen  flex flex-col ">
        <div className=" font-semibold text-2xl p-2 bg-orange-400 text-violet-950">
          <div className="grid grid-cols-2  justify-around  items-center">
            <div>कुंभ 2025</div>
            <div className="flex w-full justify-center font-semibold ">
              खोये/पाये हुए व्यक्ति की घोषणा
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse xl:flex-row">
          <div className="xl:w-8/12  gap-2 ">
            <div className=" px-4 ">
              <div className="mt-2 text-xl flex justify-center items-center w-full flex-col">
                <div className="text-2xl font-semibold">खोये हुए व्यक्ति</div>
                <hr className="m-1 text-black bg-black w-32" />
              </div>
              <div className="grid mt-3 grid-cols-2 md:grid-cols-4 gap-3">
                {presentingDocs.lostPersons.docs.map((el, index) => {
                  return (
                    <div key={el.photo + index}>
                      <div className="flex justify-center items-center">
                        <div className=" h-36 sm:h-48 md:h-32 lg:h-56 xl:h-32 2xl:h-52 flex overflow-hidden w-full">
                          <Image
                            style={{ width: "100%" }}
                            width={"100%"}
                            className="w-full"
                            src={URLS.lost_photo_base_url + el.photo}
                          ></Image>
                        </div>
                      </div>
                      <div className="text-center font-semibold text-lg p-1 bg-violet-50 capitalize">
                        {truncateString(
                          el?.name_hindi ||
                            el?.first_name + " " + el?.last_name,
                          10
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className=" px-4">
              <div className="mt-2 text-xl flex justify-center items-center w-full flex-col">
                <div className="text-2xl font-semibold">पाये हुए व्यक्ति</div>
                <hr className="m-1 text-black bg-black w-32" />
              </div>
              <div className="grid mt-3 grid-cols-2 md:grid-cols-4 gap-3">
                {presentingDocs.foundPersons.docs.map((el, index) => {
                  return (
                    <div key={el.photo + index} className="bg-white">
                      <div className="flex justify-center items-center">
                        <div className=" h-36 bg-white sm:h-48 md:h-32 lg:h-56 xl:h-32 2xl:h-52 flex overflow-hidden w-full">
                          <Image
                            style={{ width: "100%" }}
                            width={"100%"}
                            className="w-full"
                            src={URLS.found_photo_base_url + el.photo}
                          ></Image>
                        </div>
                      </div>
                      <div className="text-center p-1 font-semibold text-lg bg-violet-50 capitalize">
                        {truncateString(
                          el?.name_hindi ||
                            el?.first_name + " " + el?.last_name,
                          10
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="text-2xl lg:w-4/12 lg:-top-5 m-auto xl:min-h-screen relative  flex flex-col justify-center items-center lg:right-3  ">
            <div className="mt-2  text-2xl flex justify-center items-center w-full flex-col">
              <div className="font-semibold">{presentingTitle}</div>
              <hr className="m-1 text-black bg-black w-52" />
            </div>
            <div className="flex w-full mt-3 justify-center items-center bg-white ">
              <div className="  flex overflow-hidden w-full h-72 sm:h-96 justify-center items-start">
                <Image
                  style={{ width: "100%" }}
                  width={"100%"}
                  className="w-full"
                  src={
                    presentingDoc?.complaint_number[0]?.startsWith("F")
                      ? URLS.found_photo_base_url + presentingDoc?.photo
                      : URLS.lost_photo_base_url + presentingDoc?.photo
                  }
                ></Image>
              </div>
            </div>
            <div className=" p-2 w-full bg-violet-50 flex flex-col gap-2   font-semibold text-2xl ">
              <div>
                <span className="font-semibold">नाम: </span>
                <span className="capitalize">
                  {presentingDoc?.name_hindi ||
                    presentingDoc?.first_name + " " + presentingDoc?.last_name}
                </span>
              </div>
              <div>
                <span className="font-semibold">आयु: </span>
                {presentingDoc?.age} साल
              </div>{" "}
              <div>
                <span className="font-semibold">पोशाक: </span>
                {presentingDoc?.attire_id?.name.split("-")[1] || "_"}
              </div>
              <div>
                <span className="font-semibold">पोशाक का रंग: </span>
                {presentingDoc?.attire_color_id?.name.split("-")[1]}{" "}
              </div>{" "}
              {/* <div>
              <span className="font-semibold">अंतिम बार देखा गया: </span>
              {presentingDoc?.center_id?.sector_id?.name}
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TVConfiguration2;
