import React, { useEffect, useState } from "react";
import Statistics from "./Statistics";
import MessageContainer from "./MessageContainer";
import QuickRegistration from "./QuickRegistration";
import HelplineNumber from "../dashboard/HelplineNumber";
import Kumbh_Mela_ultra_high_res_image from "../assets/landing/Kumbh_Mela_ultra_high_res_image.jpeg";
import landingBg from "../assets/Dashboard/login_bg.jpg";
import { getData } from "../Fetch/Axios";
import URLS from "../Fetch/URLS";
import img1 from "../assets/Dashboard/UPLOGO-LATEST.png";
import img3 from "../assets/Dashboard/kumbh-logo.png";
import { useOutlet, useOutletContext } from "react-router";
import { DICT, langingPage } from "../utils/dictionary";

const LandingPageUpdated = () => {
  const [details, setDetails] = useState({});
  const outlet = useOutletContext();
  const dict = langingPage;
  const lang = outlet[1];

  const getDetails = async () => {
    const data = await getData(URLS.recent_cases);
    if (data) {
      setDetails(data.docs);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div
      className={` relative -top-16  bg-cover bg-top -mb-28`}
      style={{ backgroundImage: `url(${landingBg})` }}
    >
      <div className="bg-teal-900 relative bg-opacity-40 h-full">
        <div className="h-24"></div>
        <div className="flex w-full justify-center ">
          <img src={img1} className="h-36" alt="" />
          <img src={img3} className="h-36" alt="" />
        </div>
        <div className=" text-center text-6xl font-bold text-yellow-500">
          {dict.reuniting_loved_ones[lang]} <br></br>
          <span className=" text-orange-500">
            {dict.maha_kumbh_mela_2025[lang]}
          </span>
        </div>
        <div className="h-14"></div>
        {/* <div className="px-3 sm:w-full md:w-10/12 m-auto 24">
          <MessageContainer
            details={details?.resent_lost_and_found_cases || []}
          ></MessageContainer>
        </div> */}
        {/* <Statistics details={details?.statistics}></Statistics>
        <QuickRegistration></QuickRegistration> */}
        <div className="h-6 "></div>
        <HelplineNumber
          background={"bg-orange-200 bg-opacity-50"}
          text={"text-yellow-300"}
        ></HelplineNumber>
        <div className="h-10 "></div>
      </div>
    </div>
  );
};

export default LandingPageUpdated;
