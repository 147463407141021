import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { DICT, langingPage } from "../utils/dictionary";
import { loginFetch } from "../Fetch/Axios";

import img1 from "../assets/Dashboard/UPLOGO-LATEST.png";
import img3 from "../assets/Dashboard/kumbh-logo.png";
import optionsMaker from "../utils/optionMaker";
import "./login.css";
import { limitLength, validateMobileNumber } from "../utils/commonFunctions";
import NumericCaptcha from "./NumericCaptcha";
import CenterDropdown from "../user/User/CenterDropdown";
import URLS from "../Fetch/URLS";
import { Link, useNavigate } from "react-router-dom";

const Login = ({ setCanProceed, canProceed }) => {
  const localLang = localStorage.getItem("lang");
  const [lang, setLang] = useState(localLang || "en");

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  // handle captchat
  const [isInvalidCaptcha, setisInvalidCaptcha] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [userInput, setUserInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dict = langingPage;

  const handleValidate = () => {
    if (userInput !== captcha) {
      setErrorMessage("Invalid Captcha!");
      setisInvalidCaptcha(true);
      return false;
    } else {
      return true;
    }
  };

  const onFinish = async (values) => {
    const isCorrect = handleValidate();
    if (isCorrect) {
      setUserInput("");
    }

    if (isCorrect) {
      setLoading(true);
      const res = await loginFetch(values, setCanProceed);
      if (res) {
        setLoading(false);
        setisInvalidCaptcha(true);
        setErrorMessage("");
      } else {
        setUserInput("");
        setErrorMessage("");
        setisInvalidCaptcha(true);
      }
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const sessionToken = localStorage.getItem("sessionToken");
      if (sessionToken) {
        clearInterval(intervalId);
      }
    }, 1000);
  }, [canProceed]);

  const [options, setOptions] = useState([]);
  const [userRole, setUserRole] = useState();

  useEffect(() => {
    optionsMaker("role", "name", "_id", setOptions, "?sort=name&limit=300");
  }, []);

  return (
    <div
      className={
        "flex w-screen  min-h-screen m-auto bg-orange-100 bg-cover bg-center bg-[url('/src/assets/Dashboard/login_bg.jpg')]"
      }
    >
      <div className="absolute inset-0 w-full h-full bg-purple-100 bg-opacity-30 -z-5"></div>

      <div className="flex w-full justify-center ">
        <div className="flex w-full flex-col justify-end items-end border-orange-300  rounded-lg z-10">
          <div
            className="flex justify-center  border m-auto shadow-xl w-full  rounded-lg "
            style={{ maxWidth: "500px" }}
          >
            <div className="flex h-full flex-col w-full items-center bg-orange-300 bg-opacity-50 p-2 rounded-lg">
              <div className="text-center font-merriweather p-2 font-semibold text-lg flex flex-col items-center w-full m-auto justify-center">
                <div className="text-center font-semibold text-3xl w-full col-span-2 flex m-auto justify-center">
                  <div className="flex flex-col">
                    <div className="flex gap-3 justify-between">
                      <img src={img1} className="md:h-48 h-28" alt="" />
                      <img src={img3} className="md:h-48 h-28" alt="" />
                    </div>
                    <div className="text-green-800 ">{DICT.title1[lang]}</div>
                    <hr className="mt-1 mb-1 text-yellow-900" />
                    <div className=""> {DICT.title2[lang]}</div>
                  </div>
                </div>

                <div className="w-10/12 mt-3 z-10">
                  <Form
                    form={form}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <div className="text-start flex flex-col gap-1">
                      <Form.Item
                        name={"role_id"}
                        rules={[
                          {
                            required: true,
                            message: dict.please_select_role[lang],
                          },
                        ]}
                      >
                        <Select
                          onChange={(val) => {
                            setUserRole(val);
                          }}
                          placeholder={dict.select_role[lang]}
                          allowClear
                          className="w-full"
                        >
                          {options.map((option) => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {/* {userRole === URLS.operatorId ? (
                        <div className="mb-3 -mt-2">
                          <CenterDropdown
                            userRole={userRole}
                            defaultValue={null}
                            dict={dict}
                            lang={lang}
                            noStyle={false}
                            label={false}
                          ></CenterDropdown>
                        </div>
                      ) : (
                        <div className="-mt-3"></div>
                      )} */}
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: dict.user_name[lang],
                          },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          prefix={<UserOutlined></UserOutlined>}
                          placeholder={dict.user_name[lang]}
                          className="rounded-none"
                        />
                      </Form.Item>

                      <div className="h-5 flex justify-end relative -top-7">
                        <Link to={"/forgot-password"} className="text-black">
                          Forgot Passowrd
                        </Link>
                      </div>

                      <Form.Item
                        className="-mt-5"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: dict.please_enter_your_password[lang],
                          },
                        ]}
                      >
                        <Input.Password
                          autoComplete="off"
                          prefix={<LockOutlined></LockOutlined>}
                          placeholder={dict.password[lang]}
                          className="rounded-none"
                        />
                      </Form.Item>
                    </div>

                    <NumericCaptcha
                      setisInvalidCaptcha={setisInvalidCaptcha}
                      isInvalidCaptcha={isInvalidCaptcha}
                      captcha={captcha}
                      setCaptcha={setCaptcha}
                      userInput={userInput}
                      setUserInput={setUserInput}
                      errorMessage={errorMessage}
                      setErrorMessage={setErrorMessage}
                    ></NumericCaptcha>

                    <Form.Item noStyle>
                      <div className="flex justify-end">
                        <Button
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                          className="bg-orange-400 text-white font-semibold"
                        >
                          Login
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
const { Option } = Select;
