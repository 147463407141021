import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import optionsMaker from "../utils/optionMaker";

const CommonFormDropDownMaker = ({
  show = true,
  selectLabel,
  selectName,
  required,
  RequiredMessage,
  uri,
  responseLabelName,
  responseIdName,
  mode = "single",
  noStyle = false,
  placeholder,
  sortOptions,
  setValue = (val) => {},
  size,
  lable,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    optionsMaker(
      uri,
      responseLabelName,
      responseIdName,
      setOptions,
      sortOptions || "?sort=name&limit=300"
    );
  }, [uri]);

  return show ? (
    <Form.Item
      layout="vertical"
      label={selectLabel && <div className="font-semibold">{selectLabel}</div>}
      name={selectName}
      rules={[{ required, message: RequiredMessage }]}
      className="mb-4"
      noStyle={noStyle}
    >
      <Select
        size={size || ""}
        placeholder={placeholder}
        allowClear
        mode={mode}
        showSearch
        onChange={setValue}
        optionFilterProp="children"
        className="w-full"
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  ) : (
    ""
  );
};

export default CommonFormDropDownMaker;
const { Option } = Select;
