import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { patchData, postData } from "../../Fetch/Axios";
import CountryStateCity from "../../commonComponents/CountryStateCity";
import { nameLikeFieldsValidator } from "../../utils/nameValudator";
import URLS from "../../Fetch/URLS";
import CommonFormDropDownMaker from "../../commonComponents/CommonFormDropDownMaker";
import CommonDivider from "../../commonComponents/CommonDivider";
import { useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router";
import {
  base64ToFile,
  filterOnFinishValues,
  getSingleData,
  limitLength,
  validateMobileNumber,
} from "../../utils/commonFunctions";
import CommonUploader from "../../commonComponents/CommonUploader";
import Permissions from "../permissions/Permissions";
import { langingPage } from "../../utils/dictionary";

const UserRegistrationForm = () => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState();
  const navigate = useNavigate();

  const [updateDetails, setUpdateDetails] = useState();
  const { id } = useParams();
  const [userRole, setUserRole] = useState();

  const userSelector = useSelector(
    (state) => state.employeeSlice?.employeeUpdateEl
  );

  useEffect(() => {
    if (userSelector) {
      setUpdateDetails(userSelector);
    }
  }, [userSelector]);

  useEffect(() => {
    const userData = async () => {
      const data = await getSingleData(URLS.user + "/" + id);
      setUpdateDetails(data);
    };

    if (userSelector?.name) {
      setUpdateDetails(userSelector);
    } else if (id) {
      userData();
    }
  }, [userSelector, id, form]);

  useEffect(() => {
    if (updateDetails) {
      const values = {};
      values.name = updateDetails?.name;
      values.email = updateDetails?.email;
      values.phone = updateDetails?.phone;
      values.address = updateDetails?.address;
      values.role = updateDetails?.role?._id;

      setUserRole(updateDetails?.role?._id);

      if (updateDetails?.center_id?._id) {
        form.setFieldsValue({ center_id: updateDetails?.center_id?._id });
      }

      values.company_ud = updateDetails?.company_id;

      form.setFieldsValue(values);
    }
  }, [updateDetails, form]);

  const onFinish = async (values) => {
    setLoading(true);

    if (!updateDetails) values.passwordConfirm = values.password;
    if (updateDetails?.phone) delete values["phone"];

    if (photo) values.photo = base64ToFile(photo);

    const formData = filterOnFinishValues(values, true);

    let response;

    if (id) response = await patchData(formData, URLS.user + "/" + id);
    else response = await postData(formData, URLS.user);

    if (response?.status === "success") {
      setLoading(false);
      setUpdateDetails(null);

      if (id) {
        navigate("/user");
      }

      form.resetFields();
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto p-6 bg-white shadow-md rounded-lg mt-3 w-full">
      <CommonDivider
        backCompo={{ path: "/user" }}
        label={
          <div className="text-d9 text-2xl  flex items-end justify-between w-full">
            <div className="font-bold">
              {updateDetails
                ? dict.update_user_details[lang]
                : dict.user_registration[lang]}
            </div>
            <div className="text-xs">{dict.all_mandatory_fields[lang]}</div>
          </div>
        }
      ></CommonDivider>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ company_name: "KASH IT SOLUTION" }}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
          <CommonFormDropDownMaker
            uri={"role"}
            responseLabelName="name"
            responseIdName="_id"
            selectLabel={dict.select_role[lang]}
            selectName={"role"}
            required={true}
            RequiredMessage={dict.please_select_role[lang]}
            placeholder={dict.select_role[lang]}
            setValue={setUserRole}
          ></CommonFormDropDownMaker>

          {(userRole === URLS.operatorId ||
            userRole === URLS.aId ||
            userRole === URLS.pId) && (
            <CommonFormDropDownMaker
              uri={"center"}
              responseLabelName="name"
              responseIdName="_id"
              selectLabel={dict.select_center[lang]}
              selectName={"center_id"}
              required={true}
              noStyle={false}
              RequiredMessage={dict.please_select_center[lang]}
              placeholder={"Select center"}
              defaultValue={updateDetails?.role?._id}
              label={true}
            ></CommonFormDropDownMaker>
          )}

          {/* <CenterDropdown
            userRole={userRole}
            defaultValue={updateDetails?.role?._id}
            dict={dict}
            lang={lang}
            noStyle={true}
            label={dict.select_center[lang]}
          ></CenterDropdown> */}

          <Form.Item
            label={<div className="font-semibold">{dict.enter_name[lang]}</div>}
            name="name"
            rules={[
              {
                required: true,
                message: dict.please_enter_name[lang],
              },
              {
                validator: nameLikeFieldsValidator(
                  dict.please_enter_valid_name[lang]
                ),
              },
            ]}
            className="mb-4"
          >
            <Input
              placeholder={dict.enter_name[lang]}
              className="rounded-none "
            />
          </Form.Item>

          <Form.Item
            label={<div className="font-semibold">{dict.email_id[lang]}</div>}
            name="email"
            rules={[
              { required: true, message: dict.please_enter_email[lang] },
              { type: "email", message: dict.please_enter_valid_email[lang] },
            ]}
            className="mb-4"
          >
            <Input
              // disabled={updateDetails}
              placeholder={dict.enter_email[lang]}
              className="rounded-none "
            />
          </Form.Item>

          <Form.Item
            label={
              <div className="font-semibold">{dict.phone_number[lang]}</div>
            }
            name="phone"
            rules={[
              { required: true, message: dict.please_enter_phone_number[lang] },
              {
                pattern: /^[0-9]{10}$/,
                message: dict.please_enter_valid_phone_number[lang],
              },
            ]}
            className="mb-4"
          >
            <Input
              disabled={updateDetails?.phone}
              onChange={(e) => limitLength(e, "phone", form, 10)}
              placeholder={dict.enter_phone_number[lang]}
              className="rounded-none "
              rules={[{ validator: validateMobileNumber(dict, lang) }]}
            />
          </Form.Item>

          <Form.Item
            label={<div className="font-semibold">{dict.address[lang]}</div>}
            name="address"
            rules={[
              { required: true, message: dict.please_enter_address[lang] },
            ]}
            className="mb-4"
          >
            <Input
              placeholder={dict.enter_address[lang]}
              className="rounded-none "
            />
          </Form.Item>

          {!updateDetails && (
            <Form.Item
              label={<div className="font-semibold">Password</div>}
              name="password"
              rules={[
                { required: true, message: dict.please_enter_password[lang] },
                {
                  min: 8,
                  message: dict.password_minimum_length[lang],
                },
              ]}
              className="mb-4"
            >
              <Input.Password
                placeholder={dict.enter_password[lang]}
                className="rounded-none "
              />
            </Form.Item>
          )}

          <CommonUploader
            imgSrc={
              !photo && updateDetails?.photo
                ? URLS.user_pho_base_url + updateDetails?.photo
                : false
            }
            setPhoto={setPhoto}
          ></CommonUploader>
        </div>
        <div className="mb-4">
          <Permissions
            userRoleUserReg={userRole}
            userRegistration={true}
          ></Permissions>
        </div>
        <div className="flex justify-end mb-0">
          <Form.Item noStyle>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="w-fit rounded-none bg-5c"
            >
              {updateDetails ? dict.update[lang] : dict.register[lang]}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default UserRegistrationForm;
