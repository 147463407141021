import React, { useState } from "react";
import CommonDivider from "../commonComponents/CommonDivider";
import { Button, Form, Input, InputNumber, Select, Table } from "antd";
import { langingPage } from "../utils/dictionary";
import AudioConverter from "./AudioPlayer";
import { postData } from "../Fetch/Axios";
import URLS from "../Fetch/URLS";
import { useEffect } from "react";
const { Option } = Select;

const dict = langingPage;
let lang = "en";

const LocalAnnoucements = ({ setLocal }) => {
  const [selectedLanguages, setSelectedLanguages] = useState(["hi"]);
  const [loading, setLoading] = useState(false);

  const handleChange = (value) => {
    setSelectedLanguages(() => value);
  };

  const languages = [
    { label: dict.english[lang], value: "en" },
    { label: dict.hindi[lang], value: "hi" },
    { label: dict.marathi[lang], value: "mr" },
    {
      label: dict.manipuri[lang],
      value: "mni",
    },
    {
      label: dict.assamese[lang],
      value: "as",
    },
    {
      label: dict.raj[lang],
      value: "raj",
    },
    { label: dict.tamil[lang], value: "ta" },
    { label: dict.bengali[lang], value: "bn" },
    { label: dict.kannada[lang], value: "kn" },
    { label: dict.malayalam[lang], value: "ml" },
    { label: dict.odia[lang], value: "or" },
    { label: dict.gujarati[lang], value: "gu" },
    { label: dict.bodo[lang], value: "brx" },
    { label: dict.telugu[lang], value: "te" },
    { label: dict.punjabi[lang], value: "pa" },
    { label: dict.urdu[lang], value: "ur" },
  ];

  const [audio, setAudio] = useState([]);

  const announceFunction = async () => {
    setLoading(true);

    const announced = await postData(
      {
        lostDocs: data.filter((el, index) => el.status === "Lost"),
        foundDocs: data.filter((el, index) => el.status === "Found"),
        langs: selectedLanguages,
        defLangs: [],
        gender: "female",
      },
      URLS.announce
    );

    if (announced) {
      let finalDocs = [];

      for (const element of announced.docs) {
        finalDocs = [...finalDocs, ...element];
      }

      setAudio(() => finalDocs);
      setLoading(false);
    }
  };

  const [completed, setCompleted] = useState(false);

  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  const onFinish = (values) => {
    setData([
      ...data,
      {
        key: data.length + 1,
        ...values,
        center_id: JSON.parse(localStorage.getItem("sessionData")).center_id,
      },
    ]);
    form.resetFields();
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Lost or Found",
      dataIndex: "status",
      key: "status",
    },
  ];

  useEffect(() => {
    setLocal(true);
  }, []);

  return (
    <div>
      <CommonDivider
        label={"Local Announcements"}
        other={
          <Button onClick={() => setLocal(false)} className="bg-teal-200">
            Annoucement
          </Button>
        }
      ></CommonDivider>
      <div className="flex w-full justify-between items-center gap-2">
        <div className="flex gap-2 items-center">
          <Select
            mode="multiple"
            value={["hi"]}
            placeholder={"Select Languages"}
            options={languages}
            onChange={handleChange}
            className="min-w-40"
            style={{ minHeight: "32px" }}
          />
          <Button onClick={announceFunction} loading={loading}>
            Announce
          </Button>
        </div>

        <AudioConverter
          audio={audio}
          completed={completed}
          setCompleted={setCompleted}
        ></AudioConverter>
      </div>
      <div className="mt-3 -mb-10">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="flex flex-wrap gap-4"
        >
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[
              { required: true, message: "Please enter your first name" },
            ]}
            className="flex-1 min-w-[150px]"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[{ required: true, message: "Please enter your last name" }]}
            className="flex-1 min-w-[150px]"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Age"
            name="age"
            rules={[
              { required: true, message: "Please enter your age" },
              {
                type: "number",
                min: 1,
                max: 99,
                message: "Age must be between 1 and 99",
              },
            ]}
            className="flex-1 min-w-[100px]"
          >
            <InputNumber
              min={1}
              max={99}
              className="w-full"
              maxLength={2} // Doesn't work on InputNumber, so we use onChange validation
              controls={false} // Optional: Remove the up/down arrows
              onChange={(value) => {
                if (value && value.toString().length > 2) {
                  return;
                }
              }}
              formatter={(value) => (value ? value.toString().slice(0, 2) : "")} // Ensure only 2 digits
              parser={(value) => value.replace(/\D/g, "").slice(0, 2)} // Remove non-digits and limit to 2
            />
          </Form.Item>

          <Form.Item
            label="Lost or Found"
            name="status"
            rules={[{ required: true, message: "Please select Lost or Found" }]}
            className="flex-1 min-w-[150px]"
          >
            <Select>
              <Option value="Lost">Lost</Option>
              <Option value="Found">Found</Option>
            </Select>
          </Form.Item>

          <Form.Item className="flex items-end">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>

        <Table dataSource={data} columns={columns} className="mt-5" />
      </div>
    </div>
  );
};

export default LocalAnnoucements;
