import { MenuOutlined } from "@ant-design/icons";
import { Drawer, Button } from "antd";
import React, { useEffect, useState } from "react";
import "./navbar.css";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import DropDown from "./DropDown";
import img1 from "../assets/Dashboard/UPLOGO-LATEST.png";
import img3 from "../assets/Dashboard/kumbh-logo.png";
import { IoSettingsOutline } from "react-icons/io5";
import { DICT, langingPage } from "../utils/dictionary";

const Navbar = ({ lang, canProceed, setLang }) => {
  const dict = DICT;
  const dict1 = langingPage;
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const token = localStorage.getItem("sessionToken");

  const [menus, setMenus] = useState({});

  const items3 = (permissions) => {
    const links = [];
    if (permissions) {
      for (const el of permissions) {
        if (el?.module_?.name === "users" && el.read) {
          links.push({
            key: "6",
            label: (
              <Link
                className="text-black no-underline hover:text-green-200"
                to="/user"
              >
                {dict.emp_reg[lang]}
              </Link>
            ),
          });
        }

        if (el?.module_?.name === "role" && el?.read) {
          links.push({
            key: "Add Role",
            label: (
              <Link className="text-black no-underline" to="/role">
                {dict.role[lang]}
              </Link>
            ),
          });
        }

        if (el?.module_?.name === "permissions" && el.read) {
          links.push({
            key: "manage_permissions",
            label: (
              <button
                className="text-black w-full h-full text-start no-underline hover:text-green-200"
                onClick={() => {
                  navigate("/manage-permissions");
                }}
              >
                {dict.manage_permissions[lang]}
              </button>
            ),
          });
        }
      }
    }

    return links;
  };

  const items = (permissions) => {
    const links = [];

    if (permissions) {
      for (const el of permissions) {
        if (el?.module_?.name === "lost_person" && el.create) {
          links.push({
            key: "2",
            label: (
              <Link
                className="text-black no-underline hover:text-green-200"
                to="/register-lostperson-form"
              >
                {dict1.enroll_lost_person[lang]}
              </Link>
            ),
          });
        }
        if (el?.module_?.name === "found_person" && el.create) {
          links.push({
            key: "3",
            label: (
              <Link
                className="text-black no-underline"
                to="/register-foundperson-form"
              >
                {dict1.enroll_found_person[lang]}
              </Link>
            ),
          });
        }
      }
    }

    return links;
  };

  const items4 = (permissions) => {
    const links = [
      {
        key: "profile",
        label: (
          <Link className="text-black no-underline" to="/profile">
            {dict.profile[lang]}{" "}
          </Link>
        ),
      },
    ];

    if (permissions) {
      for (const el of permissions) {
        if (el?.module_?.name === "center" && el?.read) {
          links.push({
            key: "center",
            label: (
              <Link className="text-black no-underline" to="/center">
                {dict.add_center[lang]}{" "}
              </Link>
            ),
          });
        }
      }
    }

    return links;
  };

  const [isMis, setIsMis] = useState(false);

  const singeLinkPermission = (
    permission,
    element,
    purpuse,
    LinkName,
    path,
    pathname,
    includesPath
  ) => {
    const links = [];

    if (permission) {
      for (const el of permission) {
        if (el.module_?.name === "reports") {
          setIsMis(el[purpuse]);
        }

        if (el?.module_?.name === element && el[purpuse]) {
          return (
            <Link
              to={pathname.includes(path) ? "#" : path}
              className="no-underline text-black"
            >
              <div
                className={`h-8 text-black hover:text-black font-semibold rounded border-b flex items-center hover:bg-orange-300  px-3  ${
                  pathname.includes(includesPath || path)
                    ? "bg-green-300"
                    : "bg-teal-200"
                }`}
              >
                {LinkName}
              </div>
            </Link>
          );
        }
      }
    }

    return links;
  };

  const handoverItems = (permissions, lang) => {
    const links = [];

    if (permissions) {
      let index = 1;

      for (const el of permissions) {
        if (el?.module_?.name === "handover" && el?.read) {
          links.push({
            key: "handover" + index,
            label: (
              <Link className="text-black no-underline" to="/handover">
                {dict1.handover_list[lang]}
              </Link>
            ),
          });
        }

        if (el?.module_?.name === "handover" && el?.create) {
          links.push({
            key: "handover-receipt" + index,
            label: (
              <Link className="text-black no-underline" to="/handover-receipt">
                {dict1.handover_forms[lang]}{" "}
              </Link>
            ),
          });
        }

        index++;
      }
    }

    return links;
  };

  const [pathname, setPathame] = useState(location.pathname);

  useEffect(() => {
    if (location) {
      setPathame(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (
      (canProceed || !location.pathname.includes("welcome")) &&
      !location.pathname.includes("login")
    ) {
      let permissionInterval = setInterval(() => {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        if (permissions) {
          const users = items3(permissions, lang);
          const lostfound = items(permissions, lang);
          const setting = items4(permissions, lang);
          const handover = handoverItems(permissions, lang);

          const announceMent = singeLinkPermission(
            permissions,
            "announcement",
            "read",
            dict1.announcement[lang],
            "/announcement/page=1/limit=10/query=&",
            pathname
          );

          const callingStatus = singeLinkPermission(
            permissions,
            "calling_status",
            "read",
            dict1.calling_status[lang],
            "/calling-status",
            pathname,
            "calling"
          );

          const tvConfiguration = singeLinkPermission(
            permissions,
            "tv_configuration",
            "read",
            dict.tv_configuration[lang],
            "/tv-configuration",
            pathname
          );

          const reports = singeLinkPermission(
            permissions,
            "reports",
            "read",
            dict.reports[lang],
            "/reports",
            pathname
          );

          const dashboard = singeLinkPermission(
            permissions,
            "dashboard",
            "read",
            dict.dashboard[lang],
            "/dashboard",
            pathname
          );

          setMenus(() => {
            return {
              users,
              lostfound,
              setting,
              handover,
              announceMent,
              callingStatus,
              tvConfiguration,
              reports,
              dashboard,
            };
          });

          clearInterval(permissionInterval);
        }
      }, 500);
    }
  }, [lang, canProceed, pathname]);

  useEffect(() => {
    localStorage.setItem("mis", isMis);
  }, [isMis]);

  useEffect(() => {
    if (token) {
      setIsLogin(true);
    }
  }, [token]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const loggedIn = localStorage.getItem("sessionToken");

  useEffect(() => {
    if (!loggedIn) {
      setIsLogin(() => false);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (location.pathname) {
      setOpen(false);
    }
  }, [location.pathname]);

  return (
    <div
      className={`font-nunito ${
        location.pathname.includes("welcome")
          ? "bg-teal-200 bg-opacity-10  "
          : "bg-teal-100"
      }`}
    >
      <div className="z-50 w-full flex items-center h-16  m-auto  justify-en px-3">
        <div className="flex justify-between w-full items-center">
          <div
            className={`justify-start items-center col-span-1 flex ml-4 xs:ml-2 ${
              location.pathname.includes("welcome") ? "hidden" : "flex"
            }`}
          >
            <img src={img1} className="h-16" alt="" />
            <img src={img3} className="h-16" alt="" />
          </div>

          <div className="flex w-11/12 m-auto justify-end">
            <span className="flex items-center">
              <Button
                className={`flex bg-transparent border-none "text-white ${
                  location.pathname.includes("welcome")
                    ? "text-white"
                    : "text-balck"
                }`}
                onClick={showDrawer}
              >
                <MenuOutlined></MenuOutlined>
              </Button>
            </span>
          </div>

          <Drawer
            onClose={onClose}
            open={open}
            title={
              <div className="text-green-900">
                {dict1.maha_kumbh_mela_2025[lang]}
              </div>
            }
          >
            <div className="text-base -mt-4 flex flex-col gap-2">
              {isLogin && menus?.dashboard && menus?.dashboard}

              {isLogin && menus?.users && menus?.users?.length !== 0 && (
                <div
                  className={`h-8 text-black hover:text-black font-semibold rounded border-b flex items-center hover:bg-orange-300  px-3  ${
                    location.pathname.includes("permissions") ||
                    location.pathname.includes("role") ||
                    location.pathname.includes("user")
                      ? "bg-green-300"
                      : "bg-teal-200"
                  }`}
                >
                  <DropDown
                    textColor="text-black"
                    items={menus?.users}
                    name={dict.user[lang]}
                  ></DropDown>{" "}
                </div>
              )}

              {!isLogin && (
                <Link
                  to={"/welcome-to-lost-and-found-2025"}
                  className="no-underline text-black "
                >
                  <div
                    className={`h-8 text-black hover:text-black font-semibold rounded border-b flex items-center hover:bg-orange-300  px-3  ${
                      location.pathname.includes("to-lost-and")
                        ? "bg-green-300"
                        : "bg-teal-200"
                    }`}
                  >
                    {dict.home[lang]}
                  </div>
                </Link>
              )}

              {isLogin &&
                menus?.lostfound &&
                menus?.lostfound?.length !== 0 && (
                  <div
                    className={`h-8 text-black hover:text-black font-semibold rounded border-b flex items-center hover:bg-orange-300  px-3  ${
                      location.pathname.includes("person-form")
                        ? "bg-green-300"
                        : "bg-teal-200"
                    }`}
                  >
                    <DropDown
                      items={menus?.lostfound}
                      name={dict1.lost_and_found_enrollment[lang]}
                      textColor="text-black"
                    ></DropDown>
                  </div>
                )}

              {isLogin && menus?.handover && menus?.handover?.length !== 0 && (
                <div
                  className={`h-8 text-black hover:text-black font-semibold rounded border-b flex items-center hover:bg-orange-300  px-3  ${
                    location.pathname.includes("handover")
                      ? "bg-green-300"
                      : "bg-teal-200"
                  }`}
                >
                  <DropDown
                    items={menus?.handover}
                    name={dict.handover[lang]}
                    textColor="text-black"
                  ></DropDown>
                </div>
              )}

              {isLogin && menus?.announceMent && menus?.announceMent}
              {isLogin && menus?.callingStatus && menus?.callingStatus}

              {isLogin && menus?.tvConfiguration && menus?.tvConfiguration}
              {isLogin && menus?.reports && menus?.reports}

              {isLogin && (
                <Link
                  className="text-black no-underline "
                  to="/lost-found-search"
                >
                  <div
                    className={`h-8 text-black hover:text-black font-semibold rounded border-b flex items-center hover:bg-orange-300  px-3  ${
                      location.pathname.includes("search")
                        ? "bg-green-300"
                        : "bg-teal-200"
                    }`}
                  >
                    {dict.search[lang]}
                  </div>
                </Link>
              )}

              {isLogin && menus?.setting && menus?.setting?.length !== 0 && (
                <div
                  className={`h-8 text-black hover:text-black font-semibold rounded border-b flex items-center hover:bg-orange-300  px-3  ${
                    location.pathname.includes("/center") ||
                    location.pathname.includes("/profile")
                      ? "bg-green-300"
                      : "bg-teal-200"
                  }`}
                >
                  <DropDown
                    items={menus?.setting}
                    name={
                      <div>
                        <IoSettingsOutline className="text-xl" />
                      </div>
                    }
                    textColor="text-black"
                  ></DropDown>
                </div>
              )}
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
