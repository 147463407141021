import React, { useEffect, useState } from "react";
import { Upload, Button, message, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { getData } from "../../Fetch/Axios";
import URLS from "../../Fetch/URLS";
import dayjs from "dayjs";

const ExcelUploader = () => {
  const [sectors, setSectors] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getSector = async () => {
      const sectors = {};
      const center = await getData(URLS.center + "?fields=+sector_id");
      if (center) {
        for (const el of center.docs) {
          if (el?.sector_id) {
            sectors[el.sector_id.name] = el._id;
          }
        }
      }

      setSectors(() => sectors);
    };

    getSector();
  }, []);

  const handleFileUpload = (file) => {
    setLoading(true);
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const finalData = [];
      let el = jsonData[0];

      let required = [
        "Name",
        "Age",
        "Gender",
        "Complainant Phone",
        "Complainant Name",
        "Date",
      ];

      let keys = Object.keys(el);

      if (required.length !== keys.length) {
        return message.error("Incorrect Excel Format");
      }

      for (let index = 0; index < keys.length; index++) {
        if (required[index] !== keys[index]) {
          message.error("Incorrect Excel Format");
          return;
        }
      }
      function cleanString(str) {
        return str.replace(/\s+/g, " ").trim();
      }

      for (const element of jsonData) {
        let fName;
        let lName;

        let name = [...cleanString(element["Name"]).split(" ")];

        if (name.length > 1) {
          fName = name[0];

          name.shift();
          lName = name?.join(" ");
        } else {
          fName = name[0];
          lName = name[0];
        }
        console.log(fName, lName, name);

        let el = {
          first_name: fName,
          last_name: lName,
          age: element["Age"],
          gender: element["Gender"] || "M",
          complainant_mob_number: element["Complainant Phone"],
          complainant_name: element["Complainant Name"],
          center_id: URLS.modelCenter,
          createdAt: dayjs(new Date("29-01-2025")),
          channel: "1920",
        };

        finalData.push(el);
      }

      let res;
      // = await postData(finalData, URLS.uploadExcel);

      if (res) {
        setLoading(false);
        setSuccessMessage(res.message);
      } else {
        setLoading(false);
      }
    };

    reader.onerror = (error) => {
      message.error("Error reading the Excel file!");
    };

    reader.readAsArrayBuffer(file);
    return false; // Prevent upload, as we only need to read the file
  };

  return (
    <div className="flex w-full mt-10 flex-col justify-center items-center">
      <div className="font-semibold text-xl mb-3">{successMessage}</div>
      <Upload
        accept=".xlsx, .xls"
        beforeUpload={handleFileUpload}
        showUploadList={false}
      >
        <Button loading={loading} icon={<UploadOutlined />}>
          Upload Excel File
        </Button>
      </Upload>
    </div>
  );
};

export default ExcelUploader;
